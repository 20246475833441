import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { Box, FormControl, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { FC, useContext, useState } from "react";
import AccountMenu from "src/components/AccountMenu";
import UserContext from "src/components/UserContext";

const TenantMenu: FC = () => {
  const user = useContext(UserContext);
  const [activeTenant, setActiveTenant] = useState(user.activeTenant);
  const { tenants } = user.activeUserProfile;

  const switchTenant = (event: SelectChangeEvent) => {
    const selectedName = event.target.value;
    // TODO: Change to tenants when populated
    const selectedTenant = tenants.find((tenant) => tenant.name === selectedName);
    if (selectedTenant) {
      setActiveTenant(selectedTenant);
      user.setActiveTenant(selectedTenant);
    }
  };

  if (tenants.length > 1) {
    return (
      <Box maxWidth={200} width="100%">
        <FormControl
          sx={{
            backgroundColor: (theme) => theme.palette.common.white,
            borderRadius: "14px",
          }}
        >
          <Select
            id="tenants-select"
            value={activeTenant.name}
            onChange={switchTenant}
            IconComponent={KeyboardArrowDown}
            displayEmpty
            autoWidth
            sx={{
              backgroundColor: (theme) => theme.palette.common.white,
              borderRadius: "14px",
              paddingRight: "14px",
              "&:hover": {
                backgroundColor: (theme) => theme.palette.grey[100],
              },
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  marginTop: "8px",
                },
              },
            }}
          >
            {tenants.map((item) => (
              <MenuItem key={item.name} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    );
  }

  return (
    <Box maxWidth={200} width="100%" sx={{ display: "flex", alignItems: "center" }}>
      <Typography variant="h2">{user.activeTenant.name}</Typography>
    </Box>
  );
};

const Header: FC = () => (
  <Box>
    <Box display="flex" flexGrow="1" justifyContent="space-between">
      <TenantMenu />
      <Typography variant="h6">
        <AccountMenu />
      </Typography>
    </Box>
  </Box>
);

export default Header;
