import { Box } from "@mui/material";
import { FC } from "react";
import { Outlet } from "react-router-dom";

const PublicRoute: FC = () => (
  <Box
    sx={{
      bgcolor: "black",
      display: "flex",
      height: "100vh",
      minHeight: "700px",
      overflow: "hidden",
      width: "100%",
    }}
  >
    <Box
      sx={{
        bgcolor: "background.default",
        display: "flex",
        flex: "1 1 auto",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        overflow: "auto",
      }}
    >
      <Outlet />
    </Box>
  </Box>
);

export default PublicRoute;
