import { Button, Grid, TextField } from "@mui/material";
import { FC, useState } from "react";
import useDebugStationAction from "src/hooks/debug/useDebugStationAction";
import { notifyErrorRaw } from "../notifications";

const ChangePassword: FC<{ stationId: string }> = ({ stationId }) => {
  const [password, setPassword] = useState("");
  const { mutate } = useDebugStationAction(stationId);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        if (stationId)
          mutate({
            url: "update_password",
            body: {
              new_password: password,
            },
          });
        else notifyErrorRaw("Station ID is required");
      }}
    >
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <TextField label="password" size="small" required onChange={(event) => setPassword(event.target.value)} />
        </Grid>

        <Grid item>
          <Button variant="contained" type="submit">
            UPDATE PASSWORD
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ChangePassword;
