import {
  Card,
  CardContent,
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import React, { FC, useEffect, useReducer, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { getStationOcppLogs } from "src/api/logs";
import { notifyAxiosError } from "src/components/notifications";

type OcppLogsTableItem = {
  ocppStationId: string;
  timestamp: string;
  actionType: string;
  ocppLogType: string;
  messageId: string;
  data: Map<string, any>;
};

export enum StationLogMessageTypes {
  ANY = "ANY",
  REQUEST = "REQUEST",
  RESPONSE = "RESPONSE",
  ERROR = "ERROR",
}

const StationOcppLogsTable: FC<{ id: string }> = ({ id }) => {
  const intl = useIntl();

  const [logData, setLogData] = useState<Array<OcppLogsTableItem>>([]);

  const logDataReducer = (
    state: Array<OcppLogsTableItem>,
    action: { type: any; items?: any; messageType: string; actionType: string },
  ) => {
    switch (action.type) {
      case "SET":
        return action.items;
      case "FILTER":
        return logData.filter(
          (item) =>
            (action.messageType === StationLogMessageTypes.ANY || item.ocppLogType === action.messageType) &&
            (action.actionType === "" ||
              item.actionType.toLocaleLowerCase().includes(action.actionType.toLocaleLowerCase())),
        );
      default:
        throw new Error();
    }
  };

  const [logDataFiltered, dispatch] = useReducer(logDataReducer, []);

  const [messageTypeFilter, setMessageTypeFilter] = useState<string>(StationLogMessageTypes.ANY);

  const [actionTypeFilter, setActionTypeFilter] = useState<string>("");

  const [savedNextPageToken, setNextPageToken] = useState<string>();

  const [startDateTime, setStartDateTime] = useState<Dayjs | null>(dayjs().startOf("day"));

  const [endDateTime, setEndDateTime] = useState<Dayjs | null>(dayjs().endOf("day"));

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [rowOffset, setRowOffset] = useState(10);

  const resetPage = (newPage: number) => {
    setPage(newPage);
    setRowOffset((newPage + 1) * rowsPerPage);
  };

  const filterByMessageType = (messageTypeInput: string) => {
    setMessageTypeFilter(messageTypeInput);
    dispatch({
      type: "FILTER",
      messageType: messageTypeInput,
      actionType: actionTypeFilter,
    });
  };

  const filterByActionType = (actionTypeInput: string) => {
    setActionTypeFilter(actionTypeInput);
    dispatch({
      type: "FILTER",
      messageType: messageTypeFilter,
      actionType: actionTypeInput,
    });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);

    if ((newPage + 1) * rowsPerPage <= logDataFiltered.length) setRowOffset((newPage + 1) * rowsPerPage);
    else if (savedNextPageToken === "") setRowOffset(logData.length);
    else {
      getStationOcppLogs({
        stationId: id,
        startTime: startDateTime?.toISOString(),
        endTime: endDateTime?.toISOString(),
        nextPageToken: savedNextPageToken,
      }).then((response) => {
        const newLogData = logData.concat(
          response.data.items
            .sort((s2, s1) => dayjs(s1.timestamp).unix() - dayjs(s2.timestamp).unix())
            .map(({ ocppStationId, timestamp, actionType, ocppLogType, messageId, data }) => ({
              ocppStationId,
              timestamp: dayjs(timestamp).format("L LTS"),
              actionType,
              ocppLogType,
              messageId,
              data,
            })),
        );
        setLogData(newLogData);
        filterByMessageType(messageTypeFilter);
        filterByActionType(actionTypeFilter);
        resetPage(newPage);
        setNextPageToken(response.data.nextPageToken);
      });
    }
  };

  useEffect(() => {
    getStationOcppLogs({
      stationId: id,
      startTime: startDateTime?.toISOString(),
      endTime: endDateTime?.toISOString(),
    })
      .then((response) => {
        setLogData(
          response.data.items
            .sort((s2, s1) => dayjs(s1.timestamp).unix() - dayjs(s2.timestamp).unix())
            .map(({ ocppStationId, timestamp, actionType, ocppLogType, messageId, data }) => ({
              ocppStationId,
              timestamp: dayjs(timestamp).format("L LTS"),
              actionType,
              ocppLogType,
              messageId,
              data,
            })),
        );
        filterByMessageType(messageTypeFilter);
        filterByActionType(actionTypeFilter);
        resetPage(0);
        setNextPageToken(response.data.nextPageToken);
      })
      .catch((err) => notifyAxiosError(err, intl));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateTime, endDateTime]);

  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h3">
              <FormattedMessage id="logs" />
            </Typography>
          </Grid>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} lg={3}>
              <DateTimePicker
                label={intl.formatMessage({ id: "start" })}
                value={startDateTime}
                slotProps={{ textField: { fullWidth: true } }}
                onAccept={(value) => setStartDateTime(value)}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <DateTimePicker
                label={intl.formatMessage({ id: "end" })}
                value={endDateTime}
                slotProps={{ textField: { fullWidth: true } }}
                onAccept={(value) => setEndDateTime(value)}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <TextField
                id="input-message-type"
                select
                label={intl.formatMessage({ id: "messageType" })}
                InputProps={{ id: "input-message-type" }}
                InputLabelProps={{ htmlFor: "input-id" }}
                SelectProps={{ inputProps: { id: "input-id" } }}
                fullWidth
                defaultValue={StationLogMessageTypes.ANY}
                onChange={(event) => {
                  filterByMessageType(event.target.value);
                  resetPage(0);
                }}
              >
                {Object.keys(StationLogMessageTypes).map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} lg={3}>
              <TextField
                id="input-action-type"
                label={intl.formatMessage({ id: "actionType" })}
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                onChange={(event) => {
                  filterByActionType(event.target.value);
                  resetPage(0);
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} minHeight={100}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <FormattedMessage id="timestamp" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="messageId" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="messageType" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="actionType" />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="payload" />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {logDataFiltered.slice(page * rowsPerPage, rowOffset).map((row: OcppLogsTableItem) => (
                    <TableRow
                      key={row.messageId + row.ocppLogType}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>{row.timestamp}</TableCell>
                      <TableCell>{row.messageId}</TableCell>
                      <TableCell>{row.ocppLogType}</TableCell>
                      <TableCell>{row.actionType}</TableCell>
                      <TableCell>{JSON.stringify(row.data)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={savedNextPageToken === "" ? logDataFiltered.length : -1}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                SelectProps={{
                  inputProps: {
                    id: "select-rows-per-page",
                  },
                }}
                labelDisplayedRows={(pageInfo) =>
                  pageInfo.count !== -1
                    ? intl.formatMessage(
                        { id: "labelDisplayedRowsMax" },
                        { Www: pageInfo.from, Xxx: pageInfo.to, Yyy: pageInfo.count },
                      )
                    : intl.formatMessage(
                        { id: "labelDisplayedRows" },
                        { Www: pageInfo.from, Xxx: pageInfo.to, Yyy: logDataFiltered.length },
                      )
                }
              />
            </TableContainer>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default StationOcppLogsTable;
