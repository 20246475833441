import axios, { AxiosError, CreateAxiosDefaults, Method, ResponseType } from "axios";
import qs from "qs";
import { decryptAuthData, getActiveTenantId, logOut } from "src/services/auth";

const configuration: CreateAxiosDefaults = {
  url: "/",
  method: "get" as Method,
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  timeout: 45000,
  responseType: "json" as ResponseType,
  maxContentLength: 2000,
  validateStatus: (status: number) => status >= 200 && status < 300,
  maxRedirects: 5,
  paramsSerializer(params) {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

const axiosInstance = axios.create(configuration);
const authAxiosInstance = axios.create(configuration);

const getToken = () => {
  const authInfo = decryptAuthData();
  return `Bearer ${authInfo?.token}`;
};

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = getToken();

    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = token;
      config.headers.set("X-Tenant-Id", getActiveTenantId());
    }

    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(undefined, (error) => {
  if (error.request && error.request.status === 401) {
    // TODO consider using refresh token to get fresh JWT or send user to login screen
    logOut();
  }

  return Promise.reject(error);
});

const intlIdFromAxiosError = <T extends { code?: string }>(err: AxiosError<T>) => {
  const msg = err?.response?.data?.code ?? err?.code ?? "unknown_error";

  return msg
    .toLowerCase()
    .split("_")
    .map((word: string, index) => (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)))
    .join("");
};

export { authAxiosInstance, axiosInstance, intlIdFromAxiosError };
