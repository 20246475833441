export const currencyCodes = [
  {
    id: "AED",
    label: "UAE Dirham",
  },
  {
    id: "AFN",
    label: "Afghani",
  },
  {
    id: "ALL",
    label: "Lek",
  },
  {
    id: "AMD",
    label: "Armenian Dram",
  },
  {
    id: "ANG",
    label: "Netherlands Antillean Guilder",
  },
  {
    id: "AOA",
    label: "Kwanza",
  },
  {
    id: "ARS",
    label: "Argentine Peso",
  },
  {
    id: "AUD",
    label: "Australian Dollar",
  },
  {
    id: "AWG",
    label: "Aruban Florin",
  },
  {
    id: "AZN",
    label: "Azerbaijan Manat",
  },
  {
    id: "BAM",
    label: "Convertible Mark",
  },
  {
    id: "BBD",
    label: "Barbados Dollar",
  },
  {
    id: "BDT",
    label: "Taka",
  },
  {
    id: "BGN",
    label: "Bulgarian Lev",
  },
  {
    id: "BHD",
    label: "Bahraini Dinar",
  },
  {
    id: "BIF",
    label: "Burundi Franc",
  },
  {
    id: "BMD",
    label: "Bermudian Dollar",
  },
  {
    id: "BND",
    label: "Brunei Dollar",
  },
  {
    id: "BOB",
    label: "Boliviano",
  },
  {
    id: "BOV",
    label: "Mvdol",
  },
  {
    id: "BRL",
    label: "Brazilian Real",
  },
  {
    id: "BSD",
    label: "Bahamian Dollar",
  },
  {
    id: "BTN",
    label: "Ngultrum",
  },
  {
    id: "BWP",
    label: "Pula",
  },
  {
    id: "BYN",
    label: "Belarusian Ruble",
  },
  {
    id: "BZD",
    label: "Belize Dollar",
  },
  {
    id: "CAD",
    label: "Canadian Dollar",
  },
  {
    id: "CDF",
    label: "Congolese Franc",
  },
  {
    id: "CHE",
    label: "WIR Euro",
  },
  {
    id: "CHF",
    label: "Swiss Franc",
  },
  {
    id: "CHW",
    label: "WIR Franc",
  },
  {
    id: "CLF",
    label: "Unidad de Fomento",
  },
  {
    id: "CLP",
    label: "Chilean Peso",
  },
  {
    id: "CNY",
    label: "Yuan Renminbi",
  },
  {
    id: "COP",
    label: "Colombian Peso",
  },
  {
    id: "COU",
    label: "Unidad de Valor Real",
  },
  {
    id: "CRC",
    label: "Costa Rican Colon",
  },
  {
    id: "CUC",
    label: "Peso Convertible",
  },
  {
    id: "CUP",
    label: "Cuban Peso",
  },
  {
    id: "CVE",
    label: "Cabo Verde Escudo",
  },
  {
    id: "CZK",
    label: "Czech Koruna",
  },
  {
    id: "DJF",
    label: "Djibouti Franc",
  },
  {
    id: "DKK",
    label: "Danish Krone",
  },
  {
    id: "DOP",
    label: "Dominican Peso",
  },
  {
    id: "DZD",
    label: "Algerian Dinar",
  },
  {
    id: "EGP",
    label: "Egyptian Pound",
  },
  {
    id: "ERN",
    label: "Nakfa",
  },
  {
    id: "ETB",
    label: "Ethiopian Birr",
  },
  {
    id: "EUR",
    label: "Euro",
  },
  {
    id: "FJD",
    label: "Fiji Dollar",
  },
  {
    id: "FKP",
    label: "Falkland Islands Pound",
  },
  {
    id: "GBP",
    label: "Pound Sterling",
  },
  {
    id: "GEL",
    label: "Lari",
  },
  {
    id: "GHS",
    label: "Ghana Cedi",
  },
  {
    id: "GIP",
    label: "Gibraltar Pound",
  },
  {
    id: "GMD",
    label: "Dalasi",
  },
  {
    id: "GNF",
    label: "Guinean Franc",
  },
  {
    id: "GTQ",
    label: "Quetzal",
  },
  {
    id: "GYD",
    label: "Guyana Dollar",
  },
  {
    id: "HKD",
    label: "Hong Kong Dollar",
  },
  {
    id: "HNL",
    label: "Lempira",
  },
  {
    id: "HRK",
    label: "Kuna",
  },
  {
    id: "HTG",
    label: "Gourde",
  },
  {
    id: "HUF",
    label: "Forint",
  },
  {
    id: "IDR",
    label: "Rupiah",
  },
  {
    id: "ILS",
    label: "New Israeli Sheqel",
  },
  {
    id: "INR",
    label: "Indian Rupee",
  },
  {
    id: "IQD",
    label: "Iraqi Dinar",
  },
  {
    id: "IRR",
    label: "Iranian Rial",
  },
  {
    id: "ISK",
    label: "Iceland Krona",
  },
  {
    id: "JMD",
    label: "Jamaican Dollar",
  },
  {
    id: "JOD",
    label: "Jordanian Dinar",
  },
  {
    id: "JPY",
    label: "Yen",
  },
  {
    id: "KES",
    label: "Kenyan Shilling",
  },
  {
    id: "KGS",
    label: "Som",
  },
  {
    id: "KHR",
    label: "Riel",
  },
  {
    id: "KMF",
    label: "Comorian Franc ",
  },
  {
    id: "KPW",
    label: "North Korean Won",
  },
  {
    id: "KRW",
    label: "Won",
  },
  {
    id: "KWD",
    label: "Kuwaiti Dinar",
  },
  {
    id: "KYD",
    label: "Cayman Islands Dollar",
  },
  {
    id: "KZT",
    label: "Tenge",
  },
  {
    id: "LAK",
    label: "Lao Kip",
  },
  {
    id: "LBP",
    label: "Lebanese Pound",
  },
  {
    id: "LKR",
    label: "Sri Lanka Rupee",
  },
  {
    id: "LRD",
    label: "Liberian Dollar",
  },
  {
    id: "LSL",
    label: "Loti",
  },
  {
    id: "LYD",
    label: "Libyan Dinar",
  },
  {
    id: "MAD",
    label: "Moroccan Dirham",
  },
  {
    id: "MDL",
    label: "Moldovan Leu",
  },
  {
    id: "MGA",
    label: "Malagasy Ariary",
  },
  {
    id: "MKD",
    label: "Denar",
  },
  {
    id: "MMK",
    label: "Kyat",
  },
  {
    id: "MNT",
    label: "Tugrik",
  },
  {
    id: "MOP",
    label: "Pataca",
  },
  {
    id: "MRU",
    label: "Ouguiya",
  },
  {
    id: "MUR",
    label: "Mauritius Rupee",
  },
  {
    id: "MVR",
    label: "Rufiyaa",
  },
  {
    id: "MWK",
    label: "Malawi Kwacha",
  },
  {
    id: "MXN",
    label: "Mexican Peso",
  },
  {
    id: "MXV",
    label: "Mexican Unidad de Inversion (UDI)",
  },
  {
    id: "MYR",
    label: "Malaysian Ringgit",
  },
  {
    id: "MZN",
    label: "Mozambique Metical",
  },
  {
    id: "NAD",
    label: "Namibia Dollar",
  },
  {
    id: "NGN",
    label: "Naira",
  },
  {
    id: "NIO",
    label: "Cordoba Oro",
  },
  {
    id: "NOK",
    label: "Norwegian Krone",
  },
  {
    id: "NPR",
    label: "Nepalese Rupee",
  },
  {
    id: "NZD",
    label: "New Zealand Dollar",
  },
  {
    id: "OMR",
    label: "Rial Omani",
  },
  {
    id: "PAB",
    label: "Balboa",
  },
  {
    id: "PEN",
    label: "Sol",
  },
  {
    id: "PGK",
    label: "Kina",
  },
  {
    id: "PHP",
    label: "Philippine Peso",
  },
  {
    id: "PKR",
    label: "Pakistan Rupee",
  },
  {
    id: "PLN",
    label: "Zloty",
  },
  {
    id: "PYG",
    label: "Guarani",
  },
  {
    id: "QAR",
    label: "Qatari Rial",
  },
  {
    id: "RON",
    label: "Romanian Leu",
  },
  {
    id: "RSD",
    label: "Serbian Dinar",
  },
  {
    id: "RUB",
    label: "Russian Ruble",
  },
  {
    id: "RWF",
    label: "Rwanda Franc",
  },
  {
    id: "SAR",
    label: "Saudi Riyal",
  },
  {
    id: "SBD",
    label: "Solomon Islands Dollar",
  },
  {
    id: "SCR",
    label: "Seychelles Rupee",
  },
  {
    id: "SDG",
    label: "Sudanese Pound",
  },
  {
    id: "SEK",
    label: "Swedish Krona",
  },
  {
    id: "SGD",
    label: "Singapore Dollar",
  },
  {
    id: "SHP",
    label: "Saint Helena Pound",
  },
  {
    id: "SLL",
    label: "Leone",
  },
  {
    id: "SOS",
    label: "Somali Shilling",
  },
  {
    id: "SRD",
    label: "Surinam Dollar",
  },
  {
    id: "SSP",
    label: "South Sudanese Pound",
  },
  {
    id: "STN",
    label: "Dobra",
  },
  {
    id: "SVC",
    label: "El Salvador Colon",
  },
  {
    id: "SYP",
    label: "Syrian Pound",
  },
  {
    id: "SZL",
    label: "Lilangeni",
  },
  {
    id: "THB",
    label: "Baht",
  },
  {
    id: "TJS",
    label: "Somoni",
  },
  {
    id: "TMT",
    label: "Turkmenistan New Manat",
  },
  {
    id: "TND",
    label: "Tunisian Dinar",
  },
  {
    id: "TOP",
    label: "Pa’anga",
  },
  {
    id: "TRY",
    label: "Turkish Lira",
  },
  {
    id: "TTD",
    label: "Trinidad and Tobago Dollar",
  },
  {
    id: "TWD",
    label: "New Taiwan Dollar",
  },
  {
    id: "TZS",
    label: "Tanzanian Shilling",
  },
  {
    id: "UAH",
    label: "Hryvnia",
  },
  {
    id: "UGX",
    label: "Uganda Shilling",
  },
  {
    id: "USD",
    label: "US Dollar",
  },
  {
    id: "UYI",
    label: "Uruguay Peso en Unidades Indexadas (UI)",
  },
  {
    id: "UYU",
    label: "Peso Uruguayo",
  },
  {
    id: "UYW",
    label: "Unidad Previsional",
  },
  {
    id: "UZS",
    label: "Uzbekistan Sum",
  },
  {
    id: "VES",
    label: "Bolívar Soberano",
  },
  {
    id: "VND",
    label: "Dong",
  },
  {
    id: "VUV",
    label: "Vatu",
  },
  {
    id: "WST",
    label: "Tala",
  },
  {
    id: "YER",
    label: "Yemeni Rial",
  },
  {
    id: "ZAR",
    label: "Rand",
  },
  {
    id: "ZMW",
    label: "Zambian Kwacha",
  },
  {
    id: "ZWL",
    label: "Zimbabwe Dollar",
  },
];

export const currencyCodesMap = new Map(currencyCodes.map((i): [string, { id: string; label: string }] => [i.id, i]));
