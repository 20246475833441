import { AbilityBuilder, MongoAbility, createMongoAbility } from "@casl/ability";
import { autorun } from "mobx";
import { userProfileStore } from "src/services/auth";
import { Action, Subject } from "src/services/auth/store";

export type StaffAbility = MongoAbility<[Action, Subject]>;
export const ability = createMongoAbility();

autorun(() => {
  const { id, permissions } = userProfileStore.activeTenant;
  const { can, rules } = new AbilityBuilder<StaffAbility>(createMongoAbility);

  if (!id) return;

  permissions.forEach((permission) => {
    permission.actions.forEach((action) => {
      can(action, permission.subject);
    });
  });

  ability.update(rules);
});

export default ability;
