import { Grid } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { getStation } from "src/api/stations";
import { StationDetailsResponse, stationDetailsResponseEmpty } from "src/api/stations/interface";
import UserContext from "src/components/UserContext";
import Page from "src/components/layout/Page";
import { notifyAxiosError } from "src/components/notifications";
import SessionGraphs from "src/components/sessions/SessionGraphs";
import EvseInfo from "src/components/stations/EvseInfo";
import StationOcppLogsTable from "src/components/stations/OcppLogsTable";
import StationConfigurationTable from "src/components/stations/StationConfigurationTable";
import StationInfo from "src/components/stations/StationInfo";
import { SocketEventName, socketWrapper } from "src/providers/SocketProvider";
import { Can } from "src/services/permissions/context";

const StationDetails: FC = () => {
  const intl = useIntl();
  const pageTitle = intl.formatMessage({ id: "stationDashboard" });

  const navigate = useNavigate();
  const { id } = useParams();

  const user = useContext(UserContext);
  const [data, setData] = useState<StationDetailsResponse>(stationDetailsResponseEmpty);

  const filter = {
    ...user.entityFilter,
    stationId: id,
  };

  const fetchData = () => {
    if (!id) {
      navigate("/stations");
      return;
    }

    getStation(id)
      .then((response) => setData(response.data))
      .catch((err) => notifyAxiosError(err, intl));
  };

  useEffect(() => {
    socketWrapper.onEvent(SocketEventName.EvseState, fetchData);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title={pageTitle} breadcrumbs={[{ title: "stations", link: "/stations" }, { title: "stationDashboard" }]}>
      <Grid container rowSpacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <StationInfo station={data} />
            </Grid>

            <Grid item xs={12} lg={8}>
              <Grid container rowSpacing={3}>
                <Grid item xs={12}>
                  <SessionGraphs filter={filter} />
                </Grid>

                <Grid item xs={12}>
                  <Can I="control" a="Station">
                    <EvseInfo station={data} />
                  </Can>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Can I="control" a="Station">
            <StationConfigurationTable stationId={id!} />
          </Can>
        </Grid>
        <Grid item xs={12}>
          <Can I="control" a="Station">
            <StationOcppLogsTable id={id!} />
          </Can>
        </Grid>
      </Grid>
    </Page>
  );
};

export default StationDetails;
