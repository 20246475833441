import { FC, ReactElement } from "react";
import { Can } from "src/services/permissions/context";

interface ConditionalCanProps {
  a: string | undefined;
  I: string;
  children: ReactElement;
}

// eslint-disable-next-line import/prefer-default-export
export const ConditionalCan: FC<ConditionalCanProps> = ({ a, I, children }) => {
  if (a) {
    return (
      <Can I={I} a={a}>
        {children}
      </Can>
    );
  }
  return children;
};
