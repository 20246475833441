import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PasswordOutlinedIcon from "@mui/icons-material/PasswordOutlined";
import { Box, IconButton, MenuItem, Popover, Typography, useTheme } from "@mui/material";
import React, { useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import UserContext from "src/components/UserContext";
import { logOut } from "src/services/auth";

const UserName = () => {
  const user = useContext(UserContext);
  return (
    <Typography variant="body1" marginLeft={1}>
      {user.activeUserProfile.name}
    </Typography>
  );
};

const AccountMenu = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isOpened, setIsOpened] = useState(false);
  const [translateArrow, setTranslateArrow] = useState(false);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpened(!isOpened);
  };

  const handleClose = () => {
    setIsOpened(false);
    setAnchorEl(null);
    setTranslateArrow(true);
  };

  const handleChangePassword = async () => {
    navigate("/users/form/password");
    handleClose();
  };

  const handleLogOut = async () => {
    logOut();
    handleClose();
  };

  return (
    <Box>
      <IconButton
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        sx={{
          borderRadius: "35px",
          backgroundColor: theme.palette.info.light,
          color: theme.palette.primary.dark,
          "&:hover": {
            backgroundColor: theme.palette.info.light,
          },
        }}
        onMouseEnter={() => setTranslateArrow(true)}
        onMouseLeave={() => setTranslateArrow(false)}
      >
        <AccountCircleOutlinedIcon color="inherit" fontSize="medium" />
        <UserName />
        <KeyboardArrowDownIcon
          fontSize="medium"
          className="translate-icon"
          sx={{
            transform: translateArrow || isOpened ? "translateY(1px)" : "none",
          }}
        />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={isOpened}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{
          marginTop: "8px",
        }}
      >
        <MenuItem onClick={handleChangePassword} sx={{ padding: 2 }}>
          <PasswordOutlinedIcon color="inherit" fontSize="small" />
          <Typography variant="body1" marginLeft={1}>
            <FormattedMessage id="resetPassword" />
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleLogOut} sx={{ padding: 2 }}>
          <ExitToAppIcon color="inherit" fontSize="small" />
          <Typography variant="body1" marginLeft={1}>
            <FormattedMessage id="logout" />
          </Typography>
        </MenuItem>
      </Popover>
    </Box>
  );
};

export default AccountMenu;
