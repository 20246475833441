import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { FC, useState } from "react";
import useDebugStationAction from "src/hooks/debug/useDebugStationAction";
import { notifyErrorRaw } from "../notifications";

const certificateUseTypes = ["CentralSystemRootCertificate", "ManufacturerRootCertificate"];

const GetInstalledCertificate: FC<{ stationId: string }> = ({ stationId }) => {
  const [certificateUseType, setCertificateUseType] = useState("");
  const { mutate } = useDebugStationAction(stationId);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        if (stationId)
          mutate({
            method: "POST",
            url: "custom",
            body: {
              request_type: "GetInstalledCertificateIds",
              request_payload: { certificateType: certificateUseType },
            },
          });
        else notifyErrorRaw("Station ID is required");
      }}
    >
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={4}>
          <FormControl size="small" fullWidth required>
            <InputLabel>certificateUseType</InputLabel>
            <Select
              label="certificateUseType"
              value={certificateUseType}
              onChange={(event) => setCertificateUseType(event.target.value)}
            >
              {certificateUseTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <Button variant="contained" type="submit">
            GET INSTALLED CERTIFICATE
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default GetInstalledCertificate;
