import { Box, Grid } from "@mui/material";
import { FC, useContext } from "react";
import { useIntl } from "react-intl";
import StatusEventsCard from "src/components/StatusEvents";
import UserContext from "src/components/UserContext";
import Page from "src/components/layout/Page";
import LocationsMap from "src/components/locations/LocationsMap";
import SessionGraphs from "src/components/sessions/SessionGraphs";
import SessionMetrics from "src/components/sessions/SessionMetrics";
import { TenantStationStatusGraph } from "src/components/stations/StationStatusGraph";

const Dashboard: FC = () => {
  const intl = useIntl();
  const pageTitle = intl.formatMessage({ id: "dashboard" });

  const user = useContext(UserContext);
  const filter = user.entityFilter;

  return (
    <Page title={pageTitle} breadcrumbs={[]}>
      <Box sx={{ width: "100%" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={9}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SessionMetrics filter={filter} />
              </Grid>
              <Grid item xs={12}>
                <LocationsMap filter={filter} />
              </Grid>
              <Grid item xs={12}>
                <SessionGraphs filter={filter} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TenantStationStatusGraph />
              </Grid>
              <Grid item xs={12}>
                <StatusEventsCard filter={filter} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export default Dashboard;
