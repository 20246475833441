import { LoaderStatus } from "@googlemaps/js-api-loader";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { debounce } from "@mui/material/utils";
import parse from "autosuggest-highlight/parse";

import { FC, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";

const services = {
  autocomplete: null as google.maps.places.AutocompleteService | null,
  geocoder: null as google.maps.Geocoder | null,
};

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
  place_id: string;
}

interface GoogleAutocompleteParams {
  loaderStatus: LoaderStatus;
  countryCode: string;
  setCoordinates: (data: google.maps.LatLngLiteral) => void;
  setState: (data: string) => void;
  setCity: (data: string) => void;
  setAddress: (data: string) => void;
  setPostalCode: (data: string) => void;
}

// based on https://mui.com/material-ui/react-autocomplete/#google-maps-place
const GoogleAutocomplete: FC<GoogleAutocompleteParams> = ({
  loaderStatus,
  countryCode,
  setCoordinates,
  setState,
  setCity,
  setAddress,
  setPostalCode,
}) => {
  const intl = useIntl();

  const [value, setValue] = useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<readonly PlaceType[]>([]);

  const setExternalState = async (place: PlaceType | null) => {
    if (!services.geocoder && loaderStatus === LoaderStatus.SUCCESS) {
      services.geocoder = new google.maps.Geocoder();
    }
    if (!services.geocoder || !place) {
      return;
    }

    services.geocoder.geocode({ placeId: place.place_id }, async (results, status) => {
      if (status === google.maps.GeocoderStatus.OK && results && results[0] && results[0].address_components) {
        const result = results[0];

        if (result.geometry?.location) {
          const coords = result.geometry.location.toJSON();
          setCoordinates(coords);
        }

        result.address_components.forEach((comp) => {
          if (comp.types.includes("administrative_area_level_1")) {
            setState(comp.long_name);
          } else if (comp.types.includes("locality")) {
            setCity(comp.long_name);
          } else if (comp.types.includes("route") || comp.types.includes("street_address")) {
            setAddress(comp.long_name);
          } else if (comp.types.includes("postal_code")) {
            setPostalCode(comp.long_name);
          }
        });
      }
    });
  };

  const fetch = useMemo(
    () =>
      debounce(
        (
          input: string,
          callback?: (
            prediction: google.maps.places.AutocompletePrediction[] | null,
            status: google.maps.places.PlacesServiceStatus,
          ) => void,
        ) => {
          services.autocomplete?.getPlacePredictions(
            {
              input,
              componentRestrictions: { country: countryCode },
            },
            callback,
          );
        },
        400,
      ),
    [countryCode],
  );

  useEffect(() => {
    let active = true;

    if (!services.autocomplete && loaderStatus === LoaderStatus.SUCCESS) {
      services.autocomplete = new google.maps.places.AutocompleteService();
    }
    if (!services.autocomplete) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(inputValue, (results) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [loaderStatus, value, inputValue, fetch]);

  return (
    <Autocomplete
      fullWidth
      id="google-place-autocomplete"
      getOptionLabel={(option) => (typeof option === "string" ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No locations"
      onChange={(event: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        setExternalState(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <TextField {...params} label={intl.formatMessage({ id: "searchAddress" })} fullWidth />
      )}
      renderOption={(props, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length]),
        );

        return (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex", width: 44 }}>
                <LocationOnIcon sx={{ color: "text.secondary" }} />
              </Grid>
              <Grid item sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}>
                {parts.map((part, index) => (
                  <Box
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

export default GoogleAutocomplete;
