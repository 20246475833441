import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { CSSProperties, FC, ReactNode } from "react";
import { FormattedMessage } from "react-intl";

interface ElementBoxProps {
  title?: string;
  titleSuffix?: ReactNode;
  children?: ReactNode;
  style?: CSSProperties;
}

const ElementBox: FC<ElementBoxProps> = ({ title, titleSuffix, children, style }) => (
  <Box
    display="flex"
    flexDirection="column"
    sx={{
      padding: 2,
      marginY: 1,
      background: (theme) => theme.palette.background.default,
      border: "1px solid",
      borderColor: (theme) => theme.palette.grey[200],
      borderRadius: 1,
      ...style,
    }}
  >
    {title && (
      <Typography variant="h5" marginBottom={2}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <FormattedMessage id={title} />
          </Grid>
          {titleSuffix && <Grid item>{titleSuffix}</Grid>}
        </Grid>
      </Typography>
    )}
    {children}
  </Box>
);

export default ElementBox;
