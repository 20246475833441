import { axiosInstance } from "src/api";
import {
  TariffDetailsResponse,
  TariffSummaryFilter,
  TariffSummaryResponse,
  UpsertTariffRequest,
} from "src/api/tariffs/interfaces";

export const fetchTariffSummaries = (filter: TariffSummaryFilter) =>
  axiosInstance.get<Array<TariffSummaryResponse>>("/tariffs", {
    params: filter,
  });

export const getTariff = (id: string) => axiosInstance.get<TariffDetailsResponse>(`/tariffs/${id}`);

export const upsertTariff = (data: UpsertTariffRequest) =>
  axiosInstance.put<TariffDetailsResponse>(`/tariffs/${data.id}`, {
    ...data,
  });
