import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { FC, useState } from "react";
import useDebugStationAction from "src/hooks/debug/useDebugStationAction";
import { notifyErrorRaw } from "../notifications";

const updateTypes = ["Full", "Differential"];

const SendAuthList: FC<{ stationId: string }> = ({ stationId }) => {
  const [idTag, setIdTag] = useState<string>("");
  const [listVersion, setListVersion] = useState<number>(0);
  const [updateType, setUpdateType] = useState<string>("");
  const { mutate } = useDebugStationAction(stationId);
  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        if (stationId)
          mutate({
            method: "POST",
            url: "custom",
            body: {
              request_type: "SendLocalList",
              request_payload: {
                listVersion,
                localAuthorizationList: [{ idTag, idTagInfo: { status: "Accepted" } }],
                updateType,
              },
            },
          });
        else notifyErrorRaw("Station ID is required");
      }}
    >
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <TextField label="idTag" size="small" required onChange={(event) => setIdTag(event.target.value)} />
        </Grid>

        <Grid item xs>
          <TextField
            label="listVersion"
            size="small"
            fullWidth
            required
            onChange={(event) => setListVersion(Number(event.target.value))}
          />
        </Grid>

        <Grid item xs>
          <FormControl size="small" fullWidth required>
            <InputLabel>updateType</InputLabel>
            <Select label="updateType" value={updateType} onChange={(event) => setUpdateType(event.target.value)}>
              {updateTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs>
          <Button variant="contained" type="submit">
            SEND AUTH LIST
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default SendAuthList;
