// copied from https://raw.githubusercontent.com/LucianoGanga/country-codes-list/master/countriesData.js
// and adjusted to reduce size `cat countries.json | jq '.[] | {id: .countryCode, label: .countryNameEn}' | jq -s | pbcopy`

export const countryCodes = [
  {
    id: "AD",
    label: "Andorra",
  },
  {
    id: "AF",
    label: "Afghanistan",
  },
  {
    id: "AG",
    label: "Antigua and Barbuda",
  },
  {
    id: "AI",
    label: "Anguilla",
  },
  {
    id: "AL",
    label: "Albania",
  },
  {
    id: "AM",
    label: "Armenia",
  },
  {
    id: "AO",
    label: "Angola",
  },
  {
    id: "AQ",
    label: "Antarctica",
  },
  {
    id: "AR",
    label: "Argentina",
  },
  {
    id: "AS",
    label: "American Samoa",
  },
  {
    id: "AT",
    label: "Austria",
  },
  {
    id: "AU",
    label: "Australia",
  },
  {
    id: "AW",
    label: "Aruba",
  },
  {
    id: "AX",
    label: "Åland Islands",
  },
  {
    id: "AZ",
    label: "Azerbaijan",
  },
  {
    id: "BA",
    label: "Bosnia and Herzegovina",
  },
  {
    id: "BB",
    label: "Barbados",
  },
  {
    id: "BD",
    label: "Bangladesh",
  },
  {
    id: "BE",
    label: "Belgium",
  },
  {
    id: "BF",
    label: "Burkina Faso",
  },
  {
    id: "BG",
    label: "Bulgaria",
  },
  {
    id: "BH",
    label: "Bahrain",
  },
  {
    id: "BI",
    label: "Burundi",
  },
  {
    id: "BJ",
    label: "Benin",
  },
  {
    id: "BL",
    label: "Saint Barthélemy",
  },
  {
    id: "BM",
    label: "Bermuda",
  },
  {
    id: "BN",
    label: "Brunei Darussalam",
  },
  {
    id: "BO",
    label: "Bolivia (Plurinational State of)",
  },
  {
    id: "BQ",
    label: "Bonaire, Sint Eustatius and Saba",
  },
  {
    id: "BR",
    label: "Brazil",
  },
  {
    id: "BT",
    label: "Bhutan",
  },
  {
    id: "BV",
    label: "Bouvet Island",
  },
  {
    id: "BW",
    label: "Botswana",
  },
  {
    id: "BY",
    label: "Belarus",
  },
  {
    id: "BZ",
    label: "Belize",
  },
  {
    id: "CA",
    label: "Canada",
  },
  {
    id: "CH",
    label: "Switzerland",
  },
  {
    id: "CI",
    label: "Côte d'Ivoire",
  },
  {
    id: "CL",
    label: "Chile",
  },
  {
    id: "CM",
    label: "Cameroon",
  },
  {
    id: "CN",
    label: "China",
  },
  {
    id: "CO",
    label: "Colombia",
  },
  {
    id: "CR",
    label: "Costa Rica",
  },
  {
    id: "CU",
    label: "Cuba",
  },
  {
    id: "CV",
    label: "Cabo Verde",
  },
  {
    id: "CW",
    label: "Curaçao",
  },
  {
    id: "CX",
    label: "Christmas Island",
  },
  {
    id: "CY",
    label: "Cyprus",
  },
  {
    id: "DE",
    label: "Germany",
  },
  {
    id: "DJ",
    label: "Djibouti",
  },
  {
    id: "DK",
    label: "Denmark",
  },
  {
    id: "DM",
    label: "Dominica",
  },
  {
    id: "DZ",
    label: "Algeria",
  },
  {
    id: "EC",
    label: "Ecuador",
  },
  {
    id: "EE",
    label: "Estonia",
  },
  {
    id: "EG",
    label: "Egypt",
  },
  {
    id: "EH",
    label: "Western Sahara",
  },
  {
    id: "ER",
    label: "Eritrea",
  },
  {
    id: "ES",
    label: "Spain",
  },
  {
    id: "ET",
    label: "Ethiopia",
  },
  {
    id: "FI",
    label: "Finland",
  },
  {
    id: "FJ",
    label: "Fiji",
  },
  {
    id: "FM",
    label: "Micronesia (Federated States of)",
  },
  {
    id: "FR",
    label: "France",
  },
  {
    id: "GA",
    label: "Gabon",
  },
  {
    id: "GD",
    label: "Grenada",
  },
  {
    id: "GE",
    label: "Georgia",
  },
  {
    id: "GF",
    label: "French Guiana",
  },
  {
    id: "GG",
    label: "Guernsey",
  },
  {
    id: "GH",
    label: "Ghana",
  },
  {
    id: "GI",
    label: "Gibraltar",
  },
  {
    id: "GL",
    label: "Greenland",
  },
  {
    id: "GN",
    label: "Guinea",
  },
  {
    id: "GP",
    label: "Guadeloupe",
  },
  {
    id: "GQ",
    label: "Equatorial Guinea",
  },
  {
    id: "GR",
    label: "Greece",
  },
  {
    id: "GS",
    label: "South Georgia and the South Sandwich Islands",
  },
  {
    id: "GT",
    label: "Guatemala",
  },
  {
    id: "GU",
    label: "Guam",
  },
  {
    id: "GW",
    label: "Guinea-Bissau",
  },
  {
    id: "GY",
    label: "Guyana",
  },
  {
    id: "HK",
    label: "Hong Kong",
  },
  {
    id: "HN",
    label: "Honduras",
  },
  {
    id: "HR",
    label: "Croatia",
  },
  {
    id: "HT",
    label: "Haiti",
  },
  {
    id: "HU",
    label: "Hungary",
  },
  {
    id: "ID",
    label: "Indonesia",
  },
  {
    id: "IE",
    label: "Ireland",
  },
  {
    id: "IL",
    label: "Israel",
  },
  {
    id: "IM",
    label: "Isle of Man",
  },
  {
    id: "IN",
    label: "India",
  },
  {
    id: "IO",
    label: "British Indian Ocean Territories",
  },
  {
    id: "IQ",
    label: "Iraq",
  },
  {
    id: "IR",
    label: "Iran (Islamic Republic of)",
  },
  {
    id: "IS",
    label: "Iceland",
  },
  {
    id: "IT",
    label: "Italy",
  },
  {
    id: "JE",
    label: "Jersey",
  },
  {
    id: "JM",
    label: "Jamaica",
  },
  {
    id: "JO",
    label: "Jordan",
  },
  {
    id: "JP",
    label: "Japan",
  },
  {
    id: "KE",
    label: "Kenya",
  },
  {
    id: "KG",
    label: "Kyrgyzstan",
  },
  {
    id: "KH",
    label: "Cambodia",
  },
  {
    id: "KP",
    label: "North Korea",
  },
  {
    id: "KR",
    label: "South Korea",
  },
  {
    id: "KI",
    label: "Kiribati",
  },
  {
    id: "KN",
    label: "Saint Kitts and Nevis",
  },
  {
    id: "KW",
    label: "Kuwait",
  },
  {
    id: "KZ",
    label: "Kazakhstan",
  },
  {
    id: "LB",
    label: "Lebanon",
  },
  {
    id: "LC",
    label: "Saint Lucia",
  },
  {
    id: "LI",
    label: "Liechtenstein",
  },
  {
    id: "LK",
    label: "Sri Lanka",
  },
  {
    id: "LR",
    label: "Liberia",
  },
  {
    id: "LS",
    label: "Lesotho",
  },
  {
    id: "LT",
    label: "Lithuania",
  },
  {
    id: "LU",
    label: "Luxembourg",
  },
  {
    id: "LV",
    label: "Latvia",
  },
  {
    id: "LY",
    label: "Libya",
  },
  {
    id: "MA",
    label: "Morocco",
  },
  {
    id: "MC",
    label: "Monaco",
  },
  {
    id: "ME",
    label: "Montenegro",
  },
  {
    id: "MF",
    label: "Saint Martin (French part)",
  },
  {
    id: "MG",
    label: "Madagascar",
  },
  {
    id: "ML",
    label: "Mali",
  },
  {
    id: "MM",
    label: "Myanmar",
  },
  {
    id: "MN",
    label: "Mongolia",
  },
  {
    id: "MO",
    label: "Macao",
  },
  {
    id: "MQ",
    label: "Martinique",
  },
  {
    id: "MR",
    label: "Mauritania",
  },
  {
    id: "MS",
    label: "Montserrat",
  },
  {
    id: "MT",
    label: "Malta",
  },
  {
    id: "MU",
    label: "Mauritius",
  },
  {
    id: "MV",
    label: "Maldives",
  },
  {
    id: "MW",
    label: "Malawi",
  },
  {
    id: "MX",
    label: "Mexico",
  },
  {
    id: "MY",
    label: "Malaysia",
  },
  {
    id: "MZ",
    label: "Mozambique",
  },
  {
    id: "NA",
    label: "Namibia",
  },
  {
    id: "NC",
    label: "New Caledonia",
  },
  {
    id: "NF",
    label: "Norfolk Island",
  },
  {
    id: "NG",
    label: "Nigeria",
  },
  {
    id: "NI",
    label: "Nicaragua",
  },
  {
    id: "NO",
    label: "Norway",
  },
  {
    id: "NP",
    label: "Nepal",
  },
  {
    id: "NR",
    label: "Nauru",
  },
  {
    id: "NU",
    label: "Niue",
  },
  {
    id: "NZ",
    label: "New Zealand",
  },
  {
    id: "OM",
    label: "Oman",
  },
  {
    id: "PA",
    label: "Panama",
  },
  {
    id: "PE",
    label: "Peru",
  },
  {
    id: "PF",
    label: "French Polynesia",
  },
  {
    id: "PG",
    label: "Papua New Guinea",
  },
  {
    id: "PK",
    label: "Pakistan",
  },
  {
    id: "PL",
    label: "Poland",
  },
  {
    id: "PM",
    label: "Saint Pierre and Miquelon",
  },
  {
    id: "PN",
    label: "Pitcairn",
  },
  {
    id: "PR",
    label: "Puerto Rico",
  },
  {
    id: "PS",
    label: "Palestine, State of",
  },
  {
    id: "PT",
    label: "Portugal",
  },
  {
    id: "PW",
    label: "Palau",
  },
  {
    id: "PY",
    label: "Paraguay",
  },
  {
    id: "QA",
    label: "Qatar",
  },
  {
    id: "RE",
    label: "Réunion",
  },
  {
    id: "RO",
    label: "Romania",
  },
  {
    id: "RS",
    label: "Serbia",
  },
  {
    id: "RU",
    label: "Russia",
  },
  {
    id: "RW",
    label: "Rwanda",
  },
  {
    id: "SA",
    label: "Saudi Arabia",
  },
  {
    id: "SB",
    label: "Solomon Islands",
  },
  {
    id: "SC",
    label: "Seychelles",
  },
  {
    id: "SE",
    label: "Sweden",
  },
  {
    id: "SG",
    label: "Singapore",
  },
  {
    id: "SH",
    label: "Saint Helena, Ascension and Tristan da Cunha",
  },
  {
    id: "SI",
    label: "Slovenia",
  },
  {
    id: "SJ",
    label: "Svalbard and Jan Mayen",
  },
  {
    id: "SK",
    label: "Slovakia",
  },
  {
    id: "SL",
    label: "Sierra Leone",
  },
  {
    id: "SM",
    label: "Republic of San Marino",
  },
  {
    id: "SN",
    label: "Senegal",
  },
  {
    id: "SO",
    label: "Somalia",
  },
  {
    id: "SR",
    label: "Suriname",
  },
  {
    id: "SS",
    label: "South Sudan",
  },
  {
    id: "ST",
    label: "Sao Tome and Principe",
  },
  {
    id: "SV",
    label: "El Salvador",
  },
  {
    id: "SX",
    label: "Sint Maarten (Dutch part)",
  },
  {
    id: "SY",
    label: "Syrian Arab Republic",
  },
  {
    id: "TD",
    label: "Chad",
  },
  {
    id: "TG",
    label: "Togo",
  },
  {
    id: "TH",
    label: "Thailand",
  },
  {
    id: "TJ",
    label: "Tajikistan",
  },
  {
    id: "TK",
    label: "Tokelau",
  },
  {
    id: "TL",
    label: "Timor-Leste",
  },
  {
    id: "TM",
    label: "Turkmenistan",
  },
  {
    id: "TN",
    label: "Tunisia",
  },
  {
    id: "TO",
    label: "Tonga",
  },
  {
    id: "TR",
    label: "Turkey",
  },
  {
    id: "TT",
    label: "Trinidad and Tobago",
  },
  {
    id: "TV",
    label: "Tuvalu",
  },
  {
    id: "TZ",
    label: "United Republic of Tanzania",
  },
  {
    id: "UA",
    label: "Ukraine",
  },
  {
    id: "UG",
    label: "Uganda",
  },
  {
    id: "US",
    label: "United States of America",
  },
  {
    id: "UY",
    label: "Uruguay",
  },
  {
    id: "UZ",
    label: "Uzbekistan",
  },
  {
    id: "VC",
    label: "Saint Vincent and the Grenadines",
  },
  {
    id: "VE",
    label: "Venezuela (Bolivarian Republic of)",
  },
  {
    id: "VG",
    label: "Virgin Islands (British)",
  },
  {
    id: "VI",
    label: "Virgin Islands (U.S.)",
  },
  {
    id: "VN",
    label: "Vietnam",
  },
  {
    id: "VU",
    label: "Vanuatu",
  },
  {
    id: "WF",
    label: "Wallis and Futuna",
  },
  {
    id: "WS",
    label: "Samoa",
  },
  {
    id: "YE",
    label: "Yemen",
  },
  {
    id: "YT",
    label: "Mayotte",
  },
  {
    id: "ZA",
    label: "South Africa",
  },
  {
    id: "ZM",
    label: "Zambia",
  },
  {
    id: "ZW",
    label: "Zimbabwe",
  },
  {
    id: "SZ",
    label: "Eswatini",
  },
  {
    id: "MK",
    label: "North Macedonia",
  },
  {
    id: "PH",
    label: "Philippines",
  },
  {
    id: "NL",
    label: "Netherlands",
  },
  {
    id: "AE",
    label: "United Arab Emirates",
  },
  {
    id: "MD",
    label: "Republic of Moldova",
  },
  {
    id: "GM",
    label: "Gambia",
  },
  {
    id: "DO",
    label: "Dominican Republic",
  },
  {
    id: "SD",
    label: "Sudan",
  },
  {
    id: "LA",
    label: "Lao People's Democratic Republic",
  },
  {
    id: "TW",
    label: "Taiwan, Province of China",
  },
  {
    id: "CG",
    label: "Republic of the Congo",
  },
  {
    id: "CZ",
    label: "Czechia",
  },
  {
    id: "GB",
    label: "United Kingdom",
  },
  {
    id: "NE",
    label: "Niger",
  },
  {
    id: "CD",
    label: "Democratic Republic of the Congo",
  },
  {
    id: "BS",
    label: "Commonwealth of The Bahamas",
  },
  {
    id: "CC",
    label: "Cocos (Keeling) Islands",
  },
  {
    id: "CF",
    label: "Central African Republic",
  },
  {
    id: "CK",
    label: "Cook Islands",
  },
  {
    id: "FK",
    label: "Falkland Islands",
  },
  {
    id: "FO",
    label: "Faroe Islands",
  },
  {
    id: "HM",
    label: "Territory of Heard Island and McDonald Islands",
  },
  {
    id: "IO",
    label: "British Indian Ocean Territory",
  },
  {
    id: "KM",
    label: "Comoros",
  },
  {
    id: "KY",
    label: "Cayman Islands",
  },
  {
    id: "MH",
    label: "Republic of the Marshall Islands",
  },
  {
    id: "MP",
    label: "Commonwealth of the Northern Mariana Islands",
  },
  {
    id: "TC",
    label: "Turks and Caicos Islands",
  },
  {
    id: "TF",
    label: "French Southern and Antarctic Lands",
  },
  {
    id: "UM",
    label: "United States Minor Outlying Islands",
  },
  {
    id: "VA",
    label: "Holy See",
  },
  {
    id: "XK",
    label: "Republic of Kosovo",
  },
  {
    id: "AN",
    label: "Netherlands Antilles",
  },
];

export const countryCodesMap = new Map(countryCodes.map((i): [string, { id: string; label: string }] => [i.id, i]));
