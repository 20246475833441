import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ForwardOutlinedIcon from "@mui/icons-material/ForwardOutlined";
import HelpIcon from "@mui/icons-material/Help";
import { Box, Button, Card, CardContent, Grid, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FC, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { getStationConfiguration, putStationConfiguration } from "src/api/stations";
import { StationConfigResponse } from "src/api/stations/interface";
import { notifyAxiosError } from "src/components/notifications";

type StationConfigurationItem = {
  key: string;
  value: StationConfigurationValue;
  status: string;
  readonly: boolean;
};

type StationConfigurationValue = {
  from: string | Array<string>;
  to?: string | Array<string>;
};

const StationConfigurationTableValue: FC<{ value: StationConfigurationValue }> = ({ value }) => {
  const { from, to } = value;

  const encodeValue = (val: string | Array<string>) => {
    if (typeof val === "string") {
      return <Typography variant="body2">{val}</Typography>;
    }

    return (
      <Box display="flex" flexDirection="column">
        {val.map((item) => (
          <Typography key={item} variant="body2">
            {item}
          </Typography>
        ))}
      </Box>
    );
  };

  if (to) {
    return (
      <Box display="flex" flexDirection="row">
        {encodeValue(from)}
        <ForwardOutlinedIcon />
        {encodeValue(to)}
      </Box>
    );
  }
  return encodeValue(from);
};

const StationConfigurationStatus: FC<{ status: string }> = ({ status }) => {
  switch (status) {
    case "applied":
      return <CheckCircleIcon fontSize="small" sx={{ color: "#44D475" }} />;
    case "unchanged":
      return <CheckCircleIcon fontSize="small" sx={{ color: "#44D475" }} />;
    case "not found":
      return <HelpIcon fontSize="small" sx={{ color: "#F76E7E" }} />;
    default:
      return <CancelIcon fontSize="small" sx={{ color: "#F76E7E" }} />;
  }
};

const StationConfigurationTable: FC<{ stationId: string }> = ({ stationId }) => {
  const intl = useIntl();

  const [data, setData] = useState<Array<StationConfigurationItem>>([]);

  const splitCSV = (from: string, to?: string) => {
    if (to) {
      if (from.indexOf(",") > -1 || to.indexOf(",") > -1) {
        return { from: from.split(","), to: to.split(",") };
      }
      return { from, to };
    }

    if (from.indexOf(",") > -1) {
      return { from: from.split(",") };
    }
    return { from };
  };

  const extractValue = (item: { value: string; targetValue: string }) => {
    const { value, targetValue } = item;
    if (value === targetValue) {
      return splitCSV(value);
    }

    return splitCSV(value, targetValue);
  };

  const updateTable = (content: StationConfigResponse) => {
    setData(
      content.configuration
        .sort((s1, s2) => s1.key.localeCompare(s2.key))
        .map((item) => ({
          key: item.key,
          value: extractValue(item),
          status: item.status,
          readonly: item.readOnly,
        })),
    );
  };

  const onApply = () => {
    putStationConfiguration({ id: stationId })
      .then((response) => {
        updateTable(response.data);
      })
      .catch((err) => notifyAxiosError(err, intl));
  };

  const onLoad = () => {
    getStationConfiguration({ id: stationId })
      .then((response) => {
        updateTable(response.data);
      })
      .catch((err) => notifyAxiosError(err, intl));
  };

  return (
    <Card>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h3">
              <FormattedMessage id="configuration" />
            </Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <Button variant="contained" onClick={onLoad}>
                  <FormattedMessage id="retrieve" />
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" onClick={onApply}>
                  <FormattedMessage id="apply" />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage id="configurationKey" />
                </TableCell>
                <TableCell
                  sx={{
                    flexGrow: 1,
                  }}
                >
                  <FormattedMessage id="configurationValue" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="status" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow key={row.key} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell>{row.key}</TableCell>
                  <TableCell>
                    <StationConfigurationTableValue value={row.value} />
                  </TableCell>
                  <TableCell>
                    <StationConfigurationStatus status={row.status} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default StationConfigurationTable;
