import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { FC, useContext, useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { FormattedMessage, useIntl } from "react-intl";
import { getLocationSummaries } from "src/api/locations";
import { LocationEvseStatus } from "src/api/locations/interfaces";
import UserContext from "src/components/UserContext";
import { notifyAxiosError } from "src/components/notifications";
import { SocketEventName, socketWrapper } from "src/providers/SocketProvider";
import { statusDefinitions } from "src/theme/colors";

ChartJS.register(ArcElement, Tooltip, Legend); // Import and register elems as Lib is Tree Shakable

interface StatusValues {
  [key: string]: number;
}

interface PieChartItem {
  label: string;
  value: number;
  color: string;
}

interface StationStatusGraphParams {
  data: Array<PieChartItem>;
}

const StationStatusGraph: FC<StationStatusGraphParams> = ({ data }) => {
  const [hoverValue, setHoverValue] = useState<string>("");
  const chartData = {
    datasets: [
      {
        data: data.map((item) => item.value),
        backgroundColor: data.map((item) => item.color),
      },
    ],
  };
  const options = {
    responsive: true,
    cutout: "70%",
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: false,
      },
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
    },

    onHover: (e: any, elements: any) => {
      if (elements.length > 0) {
        setHoverValue(elements[0].element.$context.raw);
      } else {
        setHoverValue("");
      }
    },
  };

  // Filter the statusDefinitions array to include only the desired statuses
  const desiredStatusKeys = ["available", "reserved", "charging", "faulted", "unavailable"];
  const filteredStatusDefinitions = statusDefinitions.filter((status) => desiredStatusKeys.includes(status.key));

  return (
    <Card>
      <CardContent>
        <Typography variant="h3" align="left">
          <FormattedMessage id="evseStatus" />
        </Typography>
        <Box position="relative" margin="24px auto 32px auto" width="70%">
          <Doughnut data={chartData} options={options} />
          <Typography
            variant="body1"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            {hoverValue}
          </Typography>
        </Box>

        <Box display="flex" width="auto" padding="0 6px 0">
          <Grid container columnSpacing={4}>
            {filteredStatusDefinitions.map((status) => (
              <Grid item xs={6} md={6} lg={6} key={status.key}>
                <Box display="flex" alignItems="center" justifyContent="flex-start">
                  <Box
                    width="8px"
                    height="8px"
                    marginRight="8px"
                    borderRadius="50%"
                    flexShrink={0}
                    sx={{
                      backgroundColor: status.color,
                    }}
                  />
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: 1.43,
                      letterSpacing: "0.44px",
                    }}
                  >
                    <FormattedMessage id={status.key} />
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

export const TenantStationStatusGraph: FC = () => {
  const intl = useIntl();

  const user = useContext(UserContext);
  const [data, setData] = useState<Array<PieChartItem>>([]);

  const fetchData = () => {
    getLocationSummaries(user.entityFilter)
      .then((response) => {
        if (!response.data.length) {
          setData([]);
          return;
        }
        const reduced = response.data
          .map((location) => ({ ...location.evses }) as StatusValues)
          .reduce((acc, add) => {
            statusDefinitions.forEach(({ key }) => {
              acc[key] += add[key];
            });
            return acc;
          });

        setData(
          statusDefinitions
            .map(({ key, color }) => ({
              label: intl.formatMessage({ id: key }),
              color,
              value: reduced[key],
            }))
            .filter(({ value }) => value > 0),
        );
      })
      .catch((err) => notifyAxiosError(err, intl));
  };

  useEffect(() => {
    socketWrapper.onEvent(SocketEventName.EvseState, fetchData);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ width: { xs: "266px", lg: "auto" } }}>
      <StationStatusGraph data={data} />
    </Box>
  );
};

export interface LocationStationStatusParams {
  evseStatus: LocationEvseStatus;
}

export const LocationStationStatusGraph: FC<LocationStationStatusParams> = ({ evseStatus }) => {
  const intl = useIntl();

  const reduced = { ...evseStatus } as StatusValues;
  const data = statusDefinitions
    .map(({ key, color }) => ({
      label: intl.formatMessage({ id: key }),
      color,
      value: reduced[key],
    }))
    .filter(({ value }) => value > 0);

  return (
    <Box sx={{ width: { xs: "266px", lg: "auto" } }}>
      <StationStatusGraph data={data} />
    </Box>
  );
};
