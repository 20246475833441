import { Card, CardContent } from "@mui/material";
import Box from "@mui/material/Box";
import { FC, ReactNode, SyntheticEvent, useState } from "react";
import TimeRangePicker, { shortTimeRangeOptions } from "src/components/TimeRangePicker";
import { SessionsViewProps } from "src/components/sessions/interfaces";
import SimpleTabContent from "src/components/tabs/SimpleTabContent";
import SimpleTabs from "src/components/tabs/SimpleTabs";
import { EnergyConsumptionChartEmbedded } from "./EnergyConsumptionChart";
import EvseUtilizationChartEmbedded from "./EvseUtilizationChart";
import { SessionsStartedChartEmbedded } from "./SessionsStartedChart";

const SessionGraphs: FC<SessionsViewProps> = ({ filter }) => {
  // TODO Improve alignmeng of graph and x-axis
  const ranges = shortTimeRangeOptions;
  const [tabIndex, setTabIndex] = useState(0);
  const [rangeIndex, setRangeIndex] = useState(0);

  const handleTabChange = (_event: SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleRangeChange = (newValue: number) => {
    setRangeIndex(newValue);
  };

  const tabs: Array<{ title: string; tab: ReactNode }> = [
    {
      title: "sessions",
      tab: <SessionsStartedChartEmbedded ranges={ranges} rangeIndex={rangeIndex} filter={filter} />,
    },
    {
      title: "energyUsage",
      tab: <EnergyConsumptionChartEmbedded ranges={ranges} rangeIndex={rangeIndex} filter={filter} />,
    },
    {
      title: "utilisation",
      tab: <EvseUtilizationChartEmbedded filter={filter} />,
    },
  ];

  return (
    <Card>
      <CardContent>
        <Box display="flex" flexDirection="column">
          <Box display="flex" flexDirection="row">
            <SimpleTabs options={tabs.map((item) => item.title)} selected={tabIndex} onChange={handleTabChange} />
            {tabIndex !== 2 && <TimeRangePicker options={ranges} selected={rangeIndex} onChange={handleRangeChange} />}
          </Box>
        </Box>
        <SimpleTabContent tabs={tabs.map((item) => ({ key: item.title, child: item.tab }))} selected={tabIndex} />
      </CardContent>
    </Card>
  );
};

export default SessionGraphs;
