import { Button, Grid, TextField } from "@mui/material";
import { FC, useState } from "react";
import useDebugStationAction from "src/hooks/debug/useDebugStationAction";
import { notifyErrorRaw } from "../notifications";

const UpsertStationConfig: FC<{ stationId: string }> = ({ stationId }) => {
  const [configKey, setConfigKey] = useState("");
  const [value, setValue] = useState("");
  const { mutate } = useDebugStationAction(stationId);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();

        if (configKey.toLowerCase() === "authorizationkey") {
          notifyErrorRaw("Use 'UPDATE PASSWORD' button to adjust AuthorizationKey");
        } else if (stationId)
          mutate({
            method: "PUT",
            url: `configuration/${configKey}`,
            body: { [configKey]: value },
          });
        else notifyErrorRaw("Station ID is required");
      }}
    >
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <TextField label="key" size="small" required onChange={(event) => setConfigKey(event.target.value)} />
        </Grid>

        <Grid item>
          <TextField label="value" size="small" required onChange={(event) => setValue(event.target.value)} />
        </Grid>

        <Grid item>
          <Button variant="contained" type="submit">
            UPSERT CONFIG
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default UpsertStationConfig;
