import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Grid, IconButton, List, ListItem, ListItemText, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { FormikErrors } from "formik";
import { FC, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { formatDayjsTimePeriod } from "src/i18n/timePeriod";
import { TariffFormValues } from "src/pages/TariffEdit/schema";
import { ParkingExceptionTimePicker } from "./ParkingExceptionTimePicker";

// Get formik props without using spread operator (which causes es-lint error).
interface ParkingExceptionProps {
  values: TariffFormValues;
  errors: FormikErrors<TariffFormValues>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<TariffFormValues>>;
  setFieldError: (field: string, message: string | undefined) => void;
}

const ParkingException: FC<ParkingExceptionProps> = ({ values, errors, setFieldValue, setFieldError }) => {
  const intl = useIntl();
  const formikFieldName = "parkingExceptionPeriods";

  const mapToDayjs = (time: string) => dayjs(time, "HH:mm");

  const [from, setFrom] = useState<Dayjs>(mapToDayjs("20:00"));
  const [to, setTo] = useState<Dayjs>(mapToDayjs("08:00"));

  const isOverlapWithExisting = () => {
    const periods = [];
    values.parkingExceptionPeriods.forEach((p) => periods.push({ from: p.from, to: p.to }));
    periods.push({ from, to });

    const newPeriods = [];
    // Split periods that pass midnight.
    for (let i = 0; i < periods.length; i += 1) {
      const pe = periods[i];
      if (pe.to.isBefore(pe.from)) {
        newPeriods.push({ from: pe.from, to: dayjs("00:00", "HH:mm").add(1, "day") });
        newPeriods.push({ from: dayjs("00:00", "HH:mm"), to: pe.to });
      } else {
        newPeriods.push(pe);
      }
    }

    newPeriods.sort((a, b) => (a.from.isAfter(b.from) ? 1 : -1));

    for (let i = 1; i < newPeriods.length; i += 1) {
      if (newPeriods[i - 1].from.isSame(newPeriods[i].from)) {
        return true;
      }
      if (newPeriods[i - 1].to.isSame(newPeriods[i].to)) {
        return true;
      }
      if (newPeriods[i - 1].to.isAfter(newPeriods[i].from)) {
        return true;
      }
    }
    return false;
  };

  const handleAddPeriod = () => {
    if (from.isSame(to)) {
      setFieldError(formikFieldName, "fromAndToSame");
      return;
    }
    if (isOverlapWithExisting()) {
      setFieldError(formikFieldName, "timePeriodOverlap");
      return;
    }

    const newPeriods = [...values.parkingExceptionPeriods];

    newPeriods.push({ from, to });
    newPeriods.sort((a, b) => (a.from.isAfter(b.from) ? 1 : -1));
    setFieldValue(formikFieldName, newPeriods);
    setFieldError(formikFieldName, "");
  };

  const handleDeleteParkingExceptionPeriods = (index: number) => {
    if (index < 0 || index > values.parkingExceptionPeriods.length) return;
    const parkingExceptionPeriods = [...values.parkingExceptionPeriods];
    parkingExceptionPeriods.splice(index, 1);
    setFieldValue(formikFieldName, parkingExceptionPeriods);
  };

  return (
    <Grid container spacing={2} marginTop={2}>
      <Grid item xs={12}>
        <Typography variant="h6">
          <FormattedMessage id="feeExemptionPeriod" />
        </Typography>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={6}>
          <ParkingExceptionTimePicker
            label={intl.formatMessage({ id: "startTime" })}
            parkingException={from}
            setParkingException={setFrom}
          />
        </Grid>
        <Grid item xs>
          <ParkingExceptionTimePicker
            label={intl.formatMessage({ id: "endTime" })}
            parkingException={to}
            setParkingException={setTo}
            restrictedTime={from}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" onClick={handleAddPeriod} startIcon={<AddOutlinedIcon fontSize="small" />}>
          <Typography variant="h6">
            <FormattedMessage id="addTimePeriod" />
          </Typography>
        </Button>
      </Grid>
      {errors.parkingExceptionPeriods && typeof errors.parkingExceptionPeriods === "string" ? (
        <Grid item xs={12}>
          <Typography color="red">
            <FormattedMessage id={errors.parkingExceptionPeriods} />
          </Typography>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <List disablePadding>
          {values.parkingExceptionPeriods.map((row, i) => (
            <ListItem
              key={row.from.toString() + row.to.toString()}
              secondaryAction={
                <IconButton edge="end" onClick={() => handleDeleteParkingExceptionPeriods(i)}>
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemText primary={formatDayjsTimePeriod(intl, row.from, row.to)} />
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default ParkingException;
