import { axiosInstance } from "src/api";
import {
  LocationDetailsResponse,
  LocationSummaryFilter,
  LocationSummaryResponse,
  UpsertLocationProps,
} from "src/api/locations/interfaces";

export const getLocationSummaries = (filter: LocationSummaryFilter) =>
  axiosInstance.get<Array<LocationSummaryResponse>>("/location", {
    params: filter,
  });

export const getLocation = (id: string) => axiosInstance.get<LocationDetailsResponse>(`/location/${id}`);

export const upsertLocation = (data: UpsertLocationProps) =>
  axiosInstance.put<LocationDetailsResponse>(`/location/${data.id}`, {
    ...data,
  });
