import { CssBaseline, StyledEngineProvider, ThemeProvider } from "@mui/material";
import { LocalizationProvider as MuixLocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { LicenseInfo } from "@mui/x-license-pro";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useRoutes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserContext from "src/components/UserContext";
import AppIntlProvider from "src/i18n";
import routes from "src/routes";
import { userProfileStore } from "src/services/auth";
import ability from "src/services/permissions/ability";
import { AbilityContext } from "src/services/permissions/context";
import theme from "src/theme";
import { SocketContext, socketWrapper } from "./providers/SocketProvider";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUIX_PRO_KEY || "");

const App = () => {
  const routing = useRoutes(routes);
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <UserContext.Provider value={userProfileStore}>
        <AbilityContext.Provider value={ability}>
          <ThemeProvider theme={theme}>
            <MuixLocalizationProvider dateAdapter={AdapterDayjs}>
              <StyledEngineProvider injectFirst>
                <AppIntlProvider>
                  <SocketContext.Provider value={socketWrapper}>
                    <CssBaseline />
                    <ToastContainer />
                    {routing}
                  </SocketContext.Provider>
                </AppIntlProvider>
              </StyledEngineProvider>
            </MuixLocalizationProvider>
          </ThemeProvider>
        </AbilityContext.Provider>
      </UserContext.Provider>
    </QueryClientProvider>
  );
};

export default App;
