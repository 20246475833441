import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { FC, useState } from "react";
import useDebugStationAction from "src/hooks/debug/useDebugStationAction";
import { notifyErrorRaw } from "../notifications";

const hashAlgorithms = ["SHA256", "SHA384", "SHA512"];

const DeleteCertificate: FC<{ stationId: string }> = ({ stationId }) => {
  const [hashAlgorithm, setHashAlgorithm] = useState("");
  const [issuerNameHash, setIssuerNameHash] = useState("");
  const [issuerKeyHash, setIssuerKeyHash] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const { mutate } = useDebugStationAction(stationId);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        if (stationId)
          mutate({
            method: "POST",
            url: "custom",
            body: {
              request_type: "DeleteCertificate",
              request_payload: {
                certificateHashData: {
                  hashAlgorithm,
                  issuerNameHash,
                  issuerKeyHash,
                  serialNumber,
                },
              },
            },
          });
        else notifyErrorRaw("Station ID is required");
      }}
    >
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={4}>
          <FormControl size="small" fullWidth required>
            <InputLabel>hashAlgorithm</InputLabel>
            <Select
              label="hashAlgorithm"
              value={hashAlgorithm}
              onChange={(event) => setHashAlgorithm(event.target.value)}
            >
              {hashAlgorithms.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <TextField
            label="issuerNameHash"
            size="small"
            required
            multiline
            onChange={(event) => setIssuerNameHash(event.target.value)}
          />
        </Grid>

        <Grid item>
          <TextField
            label="issuerKeyHash"
            size="small"
            required
            multiline
            onChange={(event) => setIssuerKeyHash(event.target.value)}
          />
        </Grid>

        <Grid item>
          <TextField
            label="serialNumber"
            size="small"
            required
            multiline
            onChange={(event) => setSerialNumber(event.target.value)}
          />
        </Grid>

        <Grid item>
          <Button variant="contained" type="submit">
            DELETE CERTIFICATE
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default DeleteCertificate;
