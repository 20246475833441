import dayjs, { Dayjs } from "dayjs";
import { IntlShape } from "react-intl";

export const formatDayjsTimePeriod = (intl: IntlShape, from: Dayjs, to: Dayjs): string => {
  const timeDisplayFormat = "LT";
  return intl.formatMessage(
    { id: "timePeriodXxxToYyy" },
    { Xxx: from.format(timeDisplayFormat), Yyy: to.format(timeDisplayFormat) },
  );
};

export const formatTimePeriod = (intl: IntlShape, from: string, to: string): string => {
  const timeParseFormat = "HH:mm:ss";
  return formatDayjsTimePeriod(intl, dayjs(from, timeParseFormat), dayjs(to, timeParseFormat));
};
