export interface LoginRequest {
  email: string;
  password: string;
}

export interface LoginResponse {
  // contains the JWT
  token: string;
  // indicates that the password has been reset since last login, and needs to be changed
  resetPassword: boolean;
}

export interface UserProfileResponse {
  id: string;
  name: string;
  email: string;
  tenants: TenantProfileResponse[];
}

export interface TenantFeatureDTO {
  subject: string;
  actions: string[];
}

export interface TenantProfileResponse {
  id: string;
  name: string;
  logoURL: string;
  permissions: Map<string, string[]>;
}

export interface ChangePasswordRequest {
  password: string;
  newPassword: string;
}

export interface ResetPasswordRequest {
  password: string;
}

export enum AuthStatus {
  Valid = "VALID",
  Invalid = "INVALID",
  ResetPassword = "RESET_PASSWORD",
}
