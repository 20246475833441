import { LoaderStatus } from "@googlemaps/js-api-loader";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { Box } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import greyScaleMapStyle from "./map-styles";

const popUpId = "clustered-map-info-window";

interface ClusteredMapProps {
  loaderStatus: LoaderStatus;
  pins: google.maps.LatLngLiteral[];
  popUps: React.ReactElement[];
}

const ClusteredMap: FC<ClusteredMapProps> = ({ loaderStatus, pins, popUps }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [bounds, setBounds] = useState<google.maps.LatLngBounds>();
  const [map, setMap] = useState<google.maps.Map>();
  const [popUp, setPopUp] = useState<google.maps.InfoWindow>();
  const maxDefaultZoom = 11;

  useEffect(() => {
    if (loaderStatus === LoaderStatus.SUCCESS && ref.current && !map) {
      const newMap = new window.google.maps.Map(ref.current, {
        disableDefaultUI: true,
        fullscreenControl: true,
        zoomControl: true,
        zoom: maxDefaultZoom,
        center: new google.maps.LatLng(1.3521, 103.8198),
        styles: greyScaleMapStyle,
      });
      setBounds(new google.maps.LatLngBounds());
      setMap(newMap);
      setPopUp(new google.maps.InfoWindow({ minWidth: 100 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaderStatus, ref, map]);

  useEffect(() => {
    if (map && bounds) {
      const markers: google.maps.Marker[] = [];
      pins.forEach((position, index) => {
        const marker = new google.maps.Marker({ position, map, icon: "/maps/marker.svg" });
        markers.push(marker);
        bounds.extend(marker.getPosition()!);
        marker.addListener("click", () => {
          const content = (
            <Box id={popUpId} style={{ marginRight: "20px" }}>
              {popUps[index]}
            </Box>
          );
          const isPopUpOpen = !!document.getElementById(popUpId);
          const isClickingSelf = popUp?.getPosition()?.equals(new google.maps.LatLng(position));
          if (isPopUpOpen && isClickingSelf) {
            popUp?.close();
          } else {
            popUp?.setContent(ReactDOMServer.renderToString(content));
            popUp?.open({ anchor: marker, map });
          }
        });
      });
      const cluster = new MarkerClusterer({
        markers,
        map,
        renderer: {
          render: ({ count, position }) =>
            new google.maps.Marker({
              label: { text: String(count), color: "#8700FF" },
              icon: "/maps/cluster-marker.svg",
              position,
              zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
            }),
        },
      });
      google.maps.event.addListener(map, "click", () => popUp?.close());
      google.maps.event.addListener(cluster, "click", () => popUp?.close());
      // In situations where there is only 1 marker or they are concentrated in
      // a small area, we want the zoom to stay in a wide enough area to show
      // adjacent locations.
      google.maps.event.addListenerOnce(map, "bounds_changed", () => {
        const zoom = map.getZoom();
        map.setZoom(!zoom || zoom > maxDefaultZoom ? maxDefaultZoom : zoom);
      });
      map.fitBounds(bounds);
    }
  }, [map, popUp, pins, popUps, bounds]);

  return <div ref={ref} id="map" style={{ width: "100%", height: "100%", borderRadius: "12px" }} />;
};

export default ClusteredMap;
