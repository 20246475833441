import { Dayjs } from "dayjs";
import { TariffParkingExceptionPeriod, TaxDefinition } from "src/api/tariffs/interfaces";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";

export interface TariffFormValues {
  tariffId: string;
  name: string;
  description: string;
  currency: string;
  taxPercentage: number;
  taxDefinition: TaxDefinition;
  sessionFee: number;
  kwhFee: number;
  chargingHourFee: number;
  chargingHourStepSize: number;
  parkingHourFee: number;
  parkingHourStepSize: number;
  parkingGracePeriodMin: number;
  parkingHourFeeLimit: number;
  parkingExceptionPeriods: Array<TariffFormParkingExceptionPeriod>;
}

export const initialValues = (): TariffFormValues => ({
  tariffId: uuidv4(),
  name: "",
  description: "",
  currency: "SGD",
  taxPercentage: 0,
  taxDefinition: TaxDefinition.VAT_UNDEFINED,
  sessionFee: 0,
  kwhFee: 0,
  chargingHourFee: 0,
  chargingHourStepSize: 0,
  parkingHourFee: 0,
  parkingHourStepSize: 0,
  parkingGracePeriodMin: 0,
  parkingHourFeeLimit: 0,
  parkingExceptionPeriods: [],
});

const fields = {
  name: Yup.string().required("schemaRequiredTariffName"),
  currency: Yup.string().required("schemaRequiredCurrency"),
};

export const validationSchema = Yup.object().shape({
  ...fields,
});

export interface TariffFormParkingExceptionPeriod {
  from: Dayjs;
  to: Dayjs;
}

export const toRequest = (input: Array<TariffFormParkingExceptionPeriod>) => {
  const res: Array<TariffParkingExceptionPeriod> = [];
  input.forEach((el) => {
    res.push({
      from: el.from.format("HH:mm"),
      to: el.to.format("HH:mm"),
    });
  });
  return res;
};
