import { Button, Grid, TextField } from "@mui/material";
import { FC, useState } from "react";
import useDebugStationAction from "src/hooks/debug/useDebugStationAction";
import CodeSnippet from "../CodeSnippet";
import { notifyErrorRaw } from "../notifications";

const ReadStationConfig: FC<{ stationId: string }> = ({ stationId }) => {
  const [configKey, setConfigKey] = useState("");
  const [value, setValue] = useState("");
  const { mutate } = useDebugStationAction(stationId);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        if (stationId)
          mutate(
            { method: "GET", url: `configuration/${configKey}` },
            {
              onSuccess: (data) => setValue(data.data),
            },
          );
        else notifyErrorRaw("Station ID is required");
      }}
    >
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <TextField label="key" size="small" required onChange={(event) => setConfigKey(event.target.value)} />
        </Grid>

        <Grid item>
          <Button variant="contained" type="submit">
            READ CONFIG
          </Button>
        </Grid>
      </Grid>

      {value && <CodeSnippet rawSnippet={value} />}
    </form>
  );
};

export default ReadStationConfig;
