/* eslint-disable @typescript-eslint/no-unused-vars */
import { useAbility } from "@casl/react";
import {
  Card,
  CardContent,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useContext, useState } from "react";
import { useIntl } from "react-intl";
import { Navigate } from "react-router-dom";
import UserContext from "src/components/UserContext";
import Page from "src/components/layout/Page";
import useGetStationSummaries from "src/hooks/useGetStationSummaries";
import ChangePassword from "src/pages/DeveloperTools/actions/ChangePassword";
import GetLog from "src/pages/DeveloperTools/actions/GetLog";
import { AbilityContext } from "src/services/permissions/context";
import ClearChargingProfile from "./actions/ClearChargingProfile";
import ClearStationCache from "./actions/ClearStationCache";
import DeleteCertificate from "./actions/DeleteCertificate";
import GetAuthListVersion from "./actions/GetAuthListVersion";
import GetCompositeSchedule from "./actions/GetCompositeSchedule";
import GetDiagnostics from "./actions/GetDiagnostics";
import GetInstalledCertificate from "./actions/GetInstalledCertificate";
import InstallCertificate from "./actions/InstallCertificate";
import ReadStationConfig from "./actions/ReadStationConfig";
import RemoteStart from "./actions/RemoteStart";
import ReserveConnector from "./actions/ReserveConnector";
import SecureUpdateFirmware from "./actions/SecureUpdateFirmware";
import SendAuthList from "./actions/SendAuthList";
import SetChargingProfile from "./actions/SetChargingProfile";
import SetConnectorUnavailable from "./actions/SetConnectorUnavailable";
import TriggerMessage from "./actions/TriggerMessage";
import UnlockConnector from "./actions/UnlockConnector";
import UpdateFirmware from "./actions/UpdateFirmware";
import UpsertStationConfig from "./actions/UpsertStationConfig";

const DeveloperTools: FC = () => {
  const intl = useIntl();
  const pageTitle = intl.formatMessage({ id: "developerTools" });

  const user = useContext(UserContext);
  const stations = useGetStationSummaries({ tenantId: user.activeTenantId }).data?.data;
  const [stationId, setStationId] = useState("");

  const ability = useAbility(AbilityContext);
  const canDebug = ability.can("debug", "Station");
  if (!canDebug) return <Navigate to="/" />;

  return (
    <Page title={pageTitle} breadcrumbs={[]}>
      <Card>
        <CardContent>
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <Typography variant="h3">{intl.formatMessage({ id: "developerTools" })}</Typography>
            </Grid>

            {/* <Grid container item direction="column" spacing={1}>
              <Grid item>
                <Typography variant="h5">Tenant specific</Typography>
              </Grid>

              <Grid item>
                <Divider />
              </Grid>

              <Grid container item alignItems="center" spacing={1}>
                <Grid item>
                  <TextField label="tokenId" size="small" />
                </Grid>

                <Grid item>
                  <TextField label="blocked" size="small" />
                </Grid>

                <Grid item>
                  <TextField label="expiresAt" size="small" />
                </Grid>

                <Grid item>
                  <TextField label="cachePriority" size="small" />
                </Grid>

                <Grid item>
                  <Button variant="contained">UPSERT TOKEN</Button>
                </Grid>
              </Grid>

              <Grid item>
                <Divider />
              </Grid>
            </Grid> */}

            <Grid container item direction="column" spacing={5}>
              <Grid item>
                <Typography variant="h5">Station specific</Typography>
              </Grid>

              <Grid item>
                <Divider />
              </Grid>

              <Grid item>
                <FormControl size="small" fullWidth required>
                  <InputLabel>ocppStationId</InputLabel>
                  <Select
                    label="ocppStationId"
                    value={stationId}
                    onChange={(event) => setStationId(event.target.value)}
                  >
                    {stations?.map((station) => (
                      <MenuItem key={`station-id-${station.id}`} value={station.id}>
                        {station.ocppStationId}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  label="stationId"
                  size="small"
                  value={stationId}
                  onChange={(event) => setStationId(event.target.value)}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item>
                <Divider />
              </Grid>

              <Grid item>
                <ClearStationCache stationId={stationId} />
              </Grid>

              <Grid item>
                <Divider />
              </Grid>

              <Grid item>
                <ReadStationConfig stationId={stationId} />
              </Grid>

              <Grid item>
                <Divider />
              </Grid>

              <Grid item>
                <UpsertStationConfig stationId={stationId} />
              </Grid>

              <Grid item>
                <Divider />
              </Grid>

              <Grid item>
                <ChangePassword stationId={stationId} />
              </Grid>

              <Grid item>
                <Divider />
              </Grid>

              <Grid item>
                <UnlockConnector stationId={stationId} />
              </Grid>

              <Grid item>
                <Divider />
              </Grid>

              <Grid item>
                <SetConnectorUnavailable stationId={stationId} />
              </Grid>

              <Grid item>
                <Divider />
              </Grid>

              <Grid item>
                <ReserveConnector stationId={stationId} />
              </Grid>

              <Grid item>
                <Divider />
              </Grid>

              <Grid item>
                <GetAuthListVersion stationId={stationId} />
              </Grid>

              <Grid item>
                <Divider />
              </Grid>

              <Grid item>
                <SendAuthList stationId={stationId} />
              </Grid>

              <Grid item>
                <Divider />
              </Grid>

              <Grid item>
                <UpdateFirmware stationId={stationId} />
              </Grid>

              <Grid item>
                <Divider />
              </Grid>

              <Grid item>
                <SecureUpdateFirmware stationId={stationId} />
              </Grid>

              <Grid item>
                <Divider />
              </Grid>

              <Grid item>
                <GetDiagnostics stationId={stationId} />
              </Grid>

              <Grid item>
                <Divider />
              </Grid>

              <Grid item>
                <GetLog stationId={stationId} />
              </Grid>

              <Grid item>
                <Divider />
              </Grid>

              <Grid item>
                <RemoteStart stationId={stationId} />
              </Grid>

              <Grid item>
                <Divider />
              </Grid>

              <Grid item>
                <TriggerMessage stationId={stationId} />
              </Grid>

              <Grid item>
                <Divider />
              </Grid>

              <Grid item>
                <ClearChargingProfile stationId={stationId} />
              </Grid>

              <Grid item>
                <Divider />
              </Grid>

              <Grid item>
                <GetCompositeSchedule stationId={stationId} />
              </Grid>

              <Grid item>
                <Divider />
              </Grid>

              <Grid item>
                <SetChargingProfile stationId={stationId} />
              </Grid>

              <Grid item>
                <Divider />
              </Grid>

              <Grid item>
                <InstallCertificate stationId={stationId} />
              </Grid>

              <Grid item>
                <Divider />
              </Grid>

              <Grid item>
                <GetInstalledCertificate stationId={stationId} />
              </Grid>

              <Grid item>
                <Divider />
              </Grid>

              <Grid item>
                <DeleteCertificate stationId={stationId} />
              </Grid>

              <Grid item>
                <Divider />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Page>
  );
};

export default DeveloperTools;
