export interface TariffSummaryFilter {
  tenantId: string;
}

export interface UpsertTariffRequest {
  id: string;
  tenantId: string;
  name: string;
  description: string;
  currency: string;
  taxPercentage: number;
  taxDefinition: TaxDefinition;
  sessionFee: number;
  kwhFee: number;
  chargingHourFee: number;
  chargingHourStepSize: number;
  parkingHourFee: number;
  parkingHourStepSize: number;
  parkingGracePeriodMin: number;
  parkingHourFeeLimit: number;
  parkingExceptionPeriods: Array<TariffParkingExceptionPeriod>;
}

export interface TariffSummaryResponse {
  id: string;
  name: string;
  description: string;
  currency: string;
  activeId?: string;
}

export interface TariffDetailsResponse {
  id: string;
  tenantId: string;
  name: string;
  description: string;
  currency: string;
  taxPercentage: number;
  taxDefinition: TaxDefinition;
  sessionFee: number;
  kwhFee: number;
  chargingHourFee: number;
  chargingHourStepSize: number;
  parkingHourFee: number;
  parkingHourStepSize: number;
  parkingGracePeriodMin: number;
  parkingHourFeeLimit: number;
  parkingExceptionPeriods: Array<TariffParkingExceptionPeriod>;
  activeId?: string;
}

export interface TariffParkingExceptionPeriod {
  from: string;
  to: string;
}

export enum TaxDefinition {
  VAT_UNDEFINED = "VAT_UNDEFINED",
  VAT_EXCLUDED = "VAT_EXCLUDED",
  VAT_INCLUDED = "VAT_INCLUDED",
}
