import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Button, MenuItem, Popover, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import { FormattedMessage } from "react-intl";

interface DropDownMenuItem {
  title: string;
  onClick: () => void;
}

interface DropDownMenuProps {
  title: string;
  items: DropDownMenuItem[];
}

const DropDownMenu: FC<DropDownMenuProps> = ({ title, items }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isOpened, setIsOpened] = useState(false);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpened(!isOpened);
  };

  const handleClose = () => {
    setIsOpened(false);
    setAnchorEl(null);
  };

  return (
    <Box>
      <Button onClick={handleMenu} variant="contained">
        <FormattedMessage id={title} />
        {isOpened ? (
          <KeyboardArrowUpIcon color="inherit" fontSize="large" />
        ) : (
          <KeyboardArrowDownIcon color="inherit" fontSize="large" />
        )}
      </Button>
      <Popover
        anchorEl={anchorEl}
        open={isOpened}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {items.map((item) => (
          <MenuItem
            key={item.title}
            onClick={() => {
              handleClose();
              item.onClick();
            }}
            sx={{ padding: 2 }}
          >
            <Typography variant="body1" marginLeft={1}>
              <FormattedMessage id={item.title} />
            </Typography>
          </MenuItem>
        ))}
      </Popover>
    </Box>
  );
};

export default DropDownMenu;
