import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, SvgIcon } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { CSSObject, Theme, styled, useTheme } from "@mui/material/styles";
import { FC, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { ConditionalCan } from "src/components/Permission";
import UserContext from "src/components/UserContext";
import getLinks from "src/components/layout/Sidebar/links";

const drawerWidth = 240;

const overridePaperDefaults = {
  backgroundColor: "#2E3338",
  color: "#C7C7C7",
};

const openedMixin = (theme: Theme): CSSObject => ({
  ...overridePaperDefaults,

  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  ...overridePaperDefaults,

  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerFooter = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const TenantLogo: FC = () => {
  const user = useContext(UserContext);

  return (
    <Box margin="30px 20px">
      <Box component="img" height="42px" src={user.activeTenant.logoURL} alt={user.activeTenant.name} />
    </Box>
  );
};

const Sidebar: FC = () => {
  // TODO define default for open based on screen size, or have dual sidebar:
  //     * overlapping for mobile
  //     * non-overlapping (current) for desktop
  //     as explained by https://mui.com/material-ui/react-drawer/#responsive-drawer

  // TODO move opener/closer button to bottom
  // TODO adjust color scheme
  // TODO mark entry as selected

  const intl = useIntl();
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = useState(true);

  const currentLocation = useLocation();
  const sanitizedPathname = currentLocation.pathname.split("/")[1]; // Extract the first path element

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const resizeWindow = () => {
    if (window.innerWidth < theme.breakpoints.values.lg) {
      handleDrawerClose();
    } else {
      handleDrawerOpen();
    }
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);

    return () => window.removeEventListener("resize", resizeWindow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        justifyContent: "space-between",
      }}
    >
      <TenantLogo />
      <List>
        {getLinks(intl).map((item) => (
          <ConditionalCan key={item.title} I={item.action} a={item.subject}>
            <ListItem
              key={item.title}
              disablePadding
              sx={{
                display: "block",
                // Add border if href matches current location
                borderLeft: sanitizedPathname === item.href ? "4px solid #33EEAA" : "none",
              }}
            >
              <ListItemButton
                onClick={() => navigate(item.href)}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  "&:hover": {
                    "& .MuiListItemIcon-root": {
                      color: "#33EEAA",
                    },
                    "& .MuiListItemText-primary": {
                      color: "#33EEAA",
                    },
                    backgroundColor: "transparent",
                  },
                  // Add styles when the item matches
                  ...(sanitizedPathname === item.href && {
                    "& .MuiListItemIcon-root": {
                      color: "#33EEAA",
                    },
                    "& .MuiListItemText-primary": {
                      color: "#33EEAA",
                    },
                    backgroundColor: "transparent",
                  }),
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: "#C7C7C7",
                  }}
                >
                  <SvgIcon component={item.icon} inheritViewBox fontSize="large" />
                </ListItemIcon>
                <ListItemText
                  sx={{ opacity: open ? 1 : 0 }}
                  primary={item.title}
                  primaryTypographyProps={{
                    variant: "h5",
                  }}
                />
              </ListItemButton>
            </ListItem>
          </ConditionalCan>
        ))}
      </List>
      <DrawerFooter>
        <IconButton
          onClick={handleDrawerClose}
          sx={{
            ...(!open && { display: "none" }),
            color: "#C7C7C7",
          }}
        >
          {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
        <IconButton
          onClick={handleDrawerOpen}
          sx={{
            ...(open && { display: "none" }),
            color: "#C7C7C7",
          }}
        >
          {theme.direction === "rtl" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerFooter>
    </Drawer>
  );
};

export default Sidebar;
