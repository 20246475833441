export const statusDefinitions: Array<{ key: string; color: string }> = [
  { key: "available", color: "#44D475" },
  { key: "reserved", color: "#FBAC46" },
  { key: "charging", color: "#5E8FED" },

  // OCPI OUTOFORDER maps to faulted
  { key: "outoforder", color: "#F76E7E" },
  { key: "faulted", color: "#F76E7E" },

  // OCPI status INOPERATIVE maps to unavailable
  { key: "inoperative", color: "#82A1B0" },
  { key: "unavailable", color: "#82A1B0" },
  { key: "unknown", color: "#82A1B0" },
  { key: "missing", color: "#82A1B0" },
];

export const statusColors = new Map(statusDefinitions.map((item) => [item.key, item.color]));
