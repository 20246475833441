import { Paper } from "@mui/material";
import { FC } from "react";

const CodeSnippet: FC<{ rawSnippet: string }> = ({ rawSnippet }) => {
  const formattedSnippet = JSON.stringify(rawSnippet, null, 4);
  return (
    <Paper variant="outlined">
      <pre style={{ margin: 8 }}>{formattedSnippet}</pre>
    </Paper>
  );
};

export default CodeSnippet;
