export interface StatusEventsFilter {
  tenantId: string;
  locationId?: string;
  stationId?: string;
  from?: string;
  until?: string;
  statuses?: StatusEventStatus[];
}

export interface StatusEventsViewProps {
  filter: StatusEventsFilter;
}

export enum StatusEventStatus {
  Available = "AVAILABLE",
  Faulted = "FAULTED",
  Offline = "OFFLINE",
  AuthenticationFailed = "AUTH_FAILED",
  RemoteStartFailed = "REMOTE_START_FAILED",
  RemoteStopFailed = "REMOTE_STOP_FAILED",
  StartTransactionFailed = "START_TRANSACTION_FAILED",
}
