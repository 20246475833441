import { AxiosError } from "axios";
import { IntlShape } from "react-intl";
import { ToastPosition, toast } from "react-toastify";
import { intlIdFromAxiosError } from "src/api";

export interface ToastConfig {
  position?: ToastPosition;
  autoClose?: number;
  hideProgressBar?: boolean;
  closeOnClick?: boolean;
  pauseOnHover?: boolean;
  draggable?: boolean;
  progress?: undefined;
}

export const getToastConfig = (configureToast: ToastConfig = {}) => ({
  ...configureToast,
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});

export const notifySuccess = (title: string, intl: IntlShape, config: ToastConfig = {}) =>
  toast.success(intl.formatMessage({ id: title }), getToastConfig(config));

export const notifyError = (title: string, intl: IntlShape, config: ToastConfig = {}) =>
  toast.error(intl.formatMessage({ id: title }), getToastConfig(config));

export const notifyInfo = (title: string, intl: IntlShape, config: ToastConfig = {}) =>
  toast.info(intl.formatMessage({ id: title }), getToastConfig(config));

export const notifyWarning = (title: string, intl: IntlShape, config: ToastConfig = {}) =>
  toast.warning(intl.formatMessage({ id: title }), getToastConfig(config));

export const notifyAxiosError = <T extends { code?: string }>(
  err: AxiosError<T>,
  intl: IntlShape,
  config: ToastConfig = {},
) => {
  const title = intlIdFromAxiosError(err);
  notifyError(title, intl, config);
};
