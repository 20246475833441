import { Button, Grid } from "@mui/material";
import { FC, useState } from "react";
import useDebugStationAction from "src/hooks/debug/useDebugStationAction";
import CodeSnippet from "../CodeSnippet";
import { notifyErrorRaw } from "../notifications";

const GetAuthListVersion: FC<{ stationId: string }> = ({ stationId }) => {
  const [value, setValue] = useState("");
  const { mutate } = useDebugStationAction(stationId);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        if (stationId)
          mutate(
            { method: "GET", url: "local_list_version" },
            {
              onSuccess: (data) => setValue(data.data),
            },
          );
        else notifyErrorRaw("Station ID is required");
      }}
    >
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Button variant="contained" type="submit">
            GET AUTH LIST VERSION
          </Button>
        </Grid>

        <Grid item>{value && <CodeSnippet rawSnippet={value} />}</Grid>
      </Grid>
    </form>
  );
};

export default GetAuthListVersion;
