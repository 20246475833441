import {
  ChangePasswordRequest,
  LoginRequest,
  LoginResponse,
  ResetPasswordRequest,
  UserProfileResponse,
} from "src/api/auth/interfaces";
import { authAxiosInstance, axiosInstance } from "..";

export const postLogin = (params: LoginRequest) => authAxiosInstance.post<LoginResponse>("/staff/signIn", params);

export const postChangePassword = (params: ChangePasswordRequest) =>
  axiosInstance.post<LoginResponse>("/staff/changePassword", params);

export const postResetPassword = (params: ResetPasswordRequest) =>
  axiosInstance.post<LoginResponse>("/staff/resetPassword", params);

export const getUserProfile = () => axiosInstance.get<UserProfileResponse>("/staff/me");
