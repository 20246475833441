import { Box, Grid } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { getLocation } from "src/api/locations";
import { LocationDetailsResponse, locationDetailsResponseEmpty } from "src/api/locations/interfaces";
import UserContext from "src/components/UserContext";
import Page from "src/components/layout/Page";
import LocationInfo from "src/components/locations/LocationInfo";
import { notifyAxiosError } from "src/components/notifications";
import SessionGraphs from "src/components/sessions/SessionGraphs";
import SessionMetrics from "src/components/sessions/SessionMetrics";
import { LocationStationStatusGraph } from "src/components/stations/StationStatusGraph";
import StationsTable from "src/components/stations/StationsTable";
import { SocketEventName, socketWrapper } from "src/providers/SocketProvider";

const LocationDetails: FC = () => {
  const intl = useIntl();
  const pageTitle = intl.formatMessage({ id: "locationPage" });

  const navigate = useNavigate();
  const { id } = useParams();

  const user = useContext(UserContext);
  const [data, setData] = useState<LocationDetailsResponse>(locationDetailsResponseEmpty);

  const locationsFilter = {
    ...user.entityFilter,
    locationId: id,
  };

  const fetchData = () => {
    if (!id) {
      navigate("/locations");
      return;
    }

    getLocation(id)
      .then((response) => setData(response.data))
      .catch((err) => notifyAxiosError(err, intl));
  };

  useEffect(() => {
    socketWrapper.onEvent(SocketEventName.EvseState, fetchData);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title={pageTitle} breadcrumbs={[{ title: "locations", link: "/locations" }, { title: "locationPage" }]}>
      <Box sx={{ width: "100%" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <LocationInfo location={data} />
          </Grid>
          <Grid item xs={12} lg={9}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SessionMetrics filter={locationsFilter} />
              </Grid>
              <Grid item xs={12}>
                <SessionGraphs filter={locationsFilter} />
              </Grid>
              <Grid item xs={12}>
                <StationsTable filter={locationsFilter} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <LocationStationStatusGraph evseStatus={data.evses} />
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export default LocationDetails;
