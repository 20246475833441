import { Loader, LoaderStatus } from "@googlemaps/js-api-loader";
import { FC, useEffect } from "react";

interface GoogleLibLoaderParams {
  setLoadingStatus: (status: LoaderStatus) => void;
}

const GoogleLibLoader: FC<GoogleLibLoaderParams> = ({ setLoadingStatus }) => {
  const loader = new Loader({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API ?? "",
    libraries: ["places"],
  });

  useEffect(() => {
    setLoadingStatus(loader.status);

    // calling deprecated load method, as it doesn't seem to make much of a difference yet
    // https://github.com/googlemaps/js-api-loader/issues/809#issuecomment-1707284793
    // and calling loadLibrary multiple times produces errors: https://github.com/googlemaps/js-api-loader/issues/809
    loader
      .load()
      .then(() => {
        setLoadingStatus(loader.status);
      })
      .catch(() => {
        setLoadingStatus(loader.status);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};

export default GoogleLibLoader;
