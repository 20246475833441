import { PlayArrowRounded, StopRounded } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import dayjs from "dayjs";
import { FC } from "react";
import { useIntl } from "react-intl";
import { postEvseStartCharging, postEvseStopCharging } from "src/api/stations";
import { EvseDetailsResponse } from "src/api/stations/interface";
import { Can } from "src/services/permissions/context";
import { notifyAxiosError, notifyInfo } from "../../notifications";
import StatusIcon from "../StatusIcon";

const EvseCardTitle: FC<{ stationId: string; evse: EvseDetailsResponse }> = ({ stationId, evse }) => {
  const intl = useIntl();

  const startEvse = () => {
    postEvseStartCharging({
      id: stationId,
      evseId: evse.id,
    })
      .then(() => {
        notifyInfo("startEvse", intl);
      })
      .catch((err) => notifyAxiosError(err, intl));
  };

  const stopEvse = () => {
    postEvseStopCharging({
      id: stationId,
      evseId: evse.id,
    })
      .then(() => {
        notifyInfo("stopEvse", intl);
      })
      .catch((err) => notifyAxiosError(err, intl));
  };

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Grid container spacing={1}>
          <Grid item>
            <StatusIcon
              status={evse.status}
              statusOcpp={evse.statusOcpp}
              statusUpdatedAt={dayjs(evse.statusUpdatedAt)}
            />
          </Grid>

          <Grid item>
            <Typography fontWeight={700}>{evse.emi3Id}</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Can I="control" a="Station">
        <Grid item>
          <Grid container>
            <Grid item>
              <IconButton onClick={startEvse} color="primary" size="small" style={{ padding: 0, margin: 0 }}>
                <PlayArrowRounded fontSize="large" />
              </IconButton>
            </Grid>

            <Grid item>
              <IconButton onClick={stopEvse} color="primary" size="small" style={{ padding: 0, margin: 0 }}>
                <StopRounded fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Can>
    </Grid>
  );
};

export default EvseCardTitle;
