import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { getEvseUtilizationChartData } from "src/api/sessions";
import HeatMap from "src/components/HeatMap";
import { notifyAxiosError } from "src/components/notifications";
import { HeatMapData } from "../HeatMap/interfaces";
import { SessionsFilter } from "./interfaces";

const EvseUtilizationChartEmbedded: FC<{
  filter: SessionsFilter;
}> = ({ filter }) => {
  const intl = useIntl();
  const [data, setData] = useState<HeatMapData>({ items: [] });

  useEffect(() => {
    getEvseUtilizationChartData({
      ...filter,
      from: dayjs().subtract(7, "d").add(1, "h").toISOString(),
      timeBlockSize: "HOUR",
      timezone: dayjs.tz.guess(),
    })
      .then((response) => {
        if (response.data.items.length) setData(response.data);
      })
      .catch((err) => notifyAxiosError(err, intl));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <HeatMap data={data} />;
};

export default EvseUtilizationChartEmbedded;
