import CodeIcon from "@mui/icons-material/Code";
import { IntlShape } from "react-intl";
import { ReactComponent as DashboardIcon } from "src/assets/images/navbar/dashboard.svg";
import { ReactComponent as LocationsIcon } from "src/assets/images/navbar/locations.svg";
import { ReactComponent as ReportsIcon } from "src/assets/images/navbar/reports.svg";
import { ReactComponent as SessionsIcon } from "src/assets/images/navbar/sessions.svg";
import { ReactComponent as StationsIcon } from "src/assets/images/navbar/stations.svg";
import { ReactComponent as TariffsIcon } from "src/assets/images/navbar/tariffs.svg";

const links = [
  {
    href: "dashboard",
    icon: DashboardIcon,
    title: "dashboard",
    action: "read",
  },
  {
    href: "locations",
    icon: LocationsIcon,
    title: "locations",
    subject: "Location",
    action: "read",
  },
  {
    href: "stations",
    icon: StationsIcon,
    title: "stations",
    subject: "Station",
    action: "read",
  },
  {
    href: "sessions",
    icon: SessionsIcon,
    title: "sessions",
    subject: "Session",
    action: "read",
  },
  {
    href: "tariffs",
    icon: TariffsIcon,
    title: "tariffs",
    subject: "Tariff",
    action: "read",
  },
  {
    href: "reports",
    icon: ReportsIcon,
    title: "reports",
    subject: "Session",
    action: "read",
  },
  {
    href: "developer",
    icon: CodeIcon,
    title: "developerTools",
    subject: "Station",
    action: "debug",
  },
];

const getLinks = (intl: IntlShape) =>
  links.map((item) => ({
    ...item,
    title: intl.formatMessage({ id: item.title }),
  }));

export default getLinks;
