import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { Card, CardContent, Grid, IconButton, Typography } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { LocationDetailsResponse } from "src/api/locations/interfaces";
import { Can } from "src/services/permissions/context";

export interface LocationInfoProps {
  location: LocationDetailsResponse;
}

const LocationInfo: FC<LocationInfoProps> = ({ location }) => {
  const navigate = useNavigate();

  return (
    <Card>
      <CardContent>
        <Grid container direction="row" rowSpacing={1} m={0} p={0}>
          {/* First row */}
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              spacing={4}
              rowSpacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
              {/* Location name */}
              <Grid item>
                <Typography variant="h2">{location.name}</Typography>
              </Grid>

              <Grid item>
                <Grid container direction="row" alignItems="center" spacing={4}>
                  {/* Number of stations */}
                  <Grid item>
                    <Grid container direction="row" alignItems="center" spacing={1}>
                      <Grid item>
                        <Typography variant="h2">{location.stations.total}</Typography>
                      </Grid>

                      <Grid item>
                        <Typography variant="body1">
                          <FormattedMessage id="stations" />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Number of EVSEs */}
                  <Grid item>
                    <Grid container direction="row" alignItems="center" spacing={1}>
                      <Grid item>
                        <Typography variant="h2">{location.evses.total}</Typography>
                      </Grid>

                      <Grid item>
                        <Typography variant="body1">
                          <FormattedMessage id="evse" />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Settings icon button */}
                  <Grid item>
                    <Can I="update" a="Location">
                      <IconButton onClick={() => navigate(`/locations/form/edit/${location.id}`)} color="primary">
                        <SettingsOutlinedIcon />
                      </IconButton>
                    </Can>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Second row */}
          <Grid item>
            {/* Tariffs */}
            {(location.activeTariffNameAc || location.activeTariffNameDc) && (
              <Grid item xs={12}>
                <Can I="read" a="Tariff">
                  <Grid container direction="row" columnSpacing={4}>
                    {location.activeTariffNameAc && (
                      <Grid item>
                        <Typography variant="h5" display="inline">
                          <FormattedMessage id="tariffAC" /> - {location.activeTariffNameAc}
                        </Typography>
                      </Grid>
                    )}

                    {location.activeTariffNameDc && (
                      <Grid item>
                        <Typography variant="h5" display="inline">
                          <FormattedMessage id="tariffDC" /> - {location.activeTariffNameDc}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Can>
              </Grid>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default LocationInfo;
