import axios from "axios";
import CryptoJS from "crypto-js";
import AES from "crypto-js/aes";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import utc from "dayjs/plugin/utc";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { intlIdFromAxiosError } from "src/api";
import { getUserProfile, postLogin, postResetPassword } from "src/api/auth";
import { AuthStatus } from "src/api/auth/interfaces";
import { TenantPermission, TenantProfile, UserProfileStore } from "src/services/auth/store";
import { toAction, toSubject } from "./utilities";

dayjs.extend(isSameOrAfter);
dayjs.extend(utc);

export const userProfileStore = new UserProfileStore();

export interface LoginParams {
  email: string;
  password: string;
}

export interface LoginResult {
  changePasswordRequired?: boolean;
  errId?: string;
}

export interface AuthData {
  token: string;
  resetPassword: boolean;
}

// TODO check if we can use node crypto instead, crypto-js seems dead (no commit in 2 years)
// TODO alternatively store in cookie
export const encryptAuthData = (authData: AuthData) => {
  // we copy the value, to avoid storing any other properties we might have received
  const authDataForLocalStorage = AES.encrypt(
    JSON.stringify({ token: authData.token, resetPassword: authData.resetPassword }),
    process.env.REACT_APP_LOCAL_STORE_SECRET ?? "fallback",
  );
  localStorage.setItem("auth", authDataForLocalStorage.toString());
};

export const decryptAuthData = () => {
  const authData = localStorage.getItem("auth")?.toString();
  if (authData) {
    const decryptedAuthData = AES.decrypt(authData, process.env.REACT_APP_LOCAL_STORE_SECRET ?? "fallback");
    return JSON.parse(decryptedAuthData?.toString(CryptoJS.enc.Utf8)) as AuthData;
  }
  return null;
};

export const getAuthStatus = (): AuthStatus => {
  try {
    const authInfo = decryptAuthData();
    const jwt = authInfo?.token ? jwtDecode<JwtPayload>(authInfo.token) : null;

    if (dayjs.utc().isSameOrAfter(dayjs.unix(jwt?.exp ?? 0))) {
      return AuthStatus.Invalid;
    }

    return authInfo?.resetPassword ? AuthStatus.ResetPassword : AuthStatus.Valid;
  } catch (err) {
    return AuthStatus.Invalid;
  }
};

export const getActiveTenantId = (): string => userProfileStore.activeTenantId;

export const setupUserProfile = async (): Promise<LoginResult> => {
  try {
    const profileResponse = await getUserProfile();
    if (!profileResponse.data.id || profileResponse.data.tenants.length === 0) {
      return { errId: "loginResponseInvalid" };
    }

    const profile = profileResponse.data;
    userProfileStore.setActiveUser({
      ...profile,
      tenants: profile.tenants.map((tenant) => {
        const rawPermissions = new Map<string, string[]>(Object.entries(tenant.permissions));
        const permissions: TenantProfile["permissions"] = [];

        Array.from(rawPermissions.keys()).forEach((rawSubject) => {
          const subject = toSubject(rawSubject);
          if (!subject) return;

          const rawActions = rawPermissions.get(subject);

          rawActions?.forEach((rawAction) => {
            const action = toAction(rawAction);
            if (!action) return;

            const existingSubject = permissions.find((p) => p.subject === subject);
            if (existingSubject) existingSubject.actions.push(action);
            else {
              const tenantPermission: TenantPermission = { subject, actions: [action] };
              permissions.push(tenantPermission);
            }
          });
        });
        return {
          ...tenant,
          permissions,
        };
      }),
    });
    return {};
  } catch (err) {
    if (axios.isAxiosError(err)) {
      return { errId: intlIdFromAxiosError(err) };
    }

    if (err instanceof Error) {
      return { errId: String(err.message) };
    }

    return { errId: String(err) };
  }
};

export const login = async (params: LoginParams): Promise<LoginResult> => {
  const { email, password } = params;

  const cleanedLoginData = {
    email: email.trim(),
    password: password.trim(),
  };

  try {
    const response = await postLogin(cleanedLoginData);

    encryptAuthData(response.data);
    if (response.data.resetPassword) {
      return { changePasswordRequired: true };
    }

    return await setupUserProfile();
  } catch (err) {
    if (axios.isAxiosError(err)) {
      return { errId: intlIdFromAxiosError(err) };
    }

    if (err instanceof Error) {
      return { errId: String(err.message) };
    }

    return { errId: String(err) };
  }
};

export const resetPassword = async (password: string): Promise<LoginResult> => {
  const resetPasswordData = {
    password: password.trim(),
  };

  try {
    const response = await postResetPassword(resetPasswordData);

    encryptAuthData(response.data);
    if (response.data.resetPassword) {
      return { changePasswordRequired: true };
    }

    return await setupUserProfile();
  } catch (err) {
    if (axios.isAxiosError(err)) {
      return { errId: intlIdFromAxiosError(err) };
    }

    if (err instanceof Error) {
      return { errId: String(err.message) };
    }

    return { errId: String(err) };
  }
};

export const logOut = () => {
  localStorage.removeItem("auth");
  userProfileStore.resetActiveUser();
  window.location.reload();
};
