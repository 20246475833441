import { useAbility } from "@casl/react";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { getSessionMetrics } from "src/api/sessions";
import { SessionMetricsResponse, emptySessionMetricsResponse } from "src/api/sessions/interfaces";
import TimeRangePicker, { TimeRangeOption } from "src/components/TimeRangePicker";
import { notifyAxiosError } from "src/components/notifications";
import { SessionsFilter, SessionsViewProps } from "src/components/sessions/interfaces";
import SimpleTabContent from "src/components/tabs/SimpleTabContent";
import { AbilityContext } from "src/services/permissions/context";
import { rangeMapping } from "./range-utilities";

const metricsFormatters: { [key: string]: (val: number, intl: IntlShape, revenueCurrency?: string) => string } = {
  activeSessions: (val, intl) => intl.formatNumber(val),
  totalSessions: (val, intl) => intl.formatNumber(val),
  totalPower: (val, intl) => intl.formatNumber(val / 1000),
  totalRevenue: (val, intl, revenueCurrency?: string) =>
    intl.formatNumber(val, {
      style: revenueCurrency ? "currency" : undefined,
      // Assumes each tenant only uses one currency for now
      currency: revenueCurrency ?? undefined,
      currencyDisplay: revenueCurrency ? "narrowSymbol" : undefined,
    }),
};

const includeMetrics = new Set(Object.keys(metricsFormatters));

const SessionMetricGroup: FC<{
  range: TimeRangeOption;
  filter: SessionsFilter;
}> = ({ range, filter }) => {
  const intl = useIntl();
  const ability = useAbility(AbilityContext);

  const [data, setData] = useState<SessionMetricsResponse>(emptySessionMetricsResponse);
  useEffect(() => {
    getSessionMetrics({
      ...filter,
      timeRange: rangeMapping[range],
    })
      .then((response) => setData(response.data))
      .catch((err) => notifyAxiosError(err, intl));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
      {Object.entries(data)
        .filter(([key]) => includeMetrics.has(key) && (key === "totalRevenue" ? ability.can("read", "Tariff") : true))
        .map(([key, metric]) => (
          <Box display="flex" flexDirection="column" alignItems="flex-start" key={key}>
            <Typography variant="body1" margin={1}>
              <FormattedMessage id={key} />
            </Typography>
            <Box display="flex" alignItems="center">
              <Typography variant="h2" margin={1} marginRight={0}>
                {metricsFormatters[key](metric.value, intl, data.currency)}
              </Typography>
              {metric.status && (
                <>
                  {metric.status === "UP" ? (
                    <ArrowDropUp style={{ color: "#33CC4B", margin: 0 }} />
                  ) : (
                    <ArrowDropDown style={{ color: "#E1345E" }} />
                  )}
                  <span style={{ color: metric.status === "UP" ? "#33CC4B" : "#E1345E" }}>{metric.report}%</span>
                </>
              )}
            </Box>
          </Box>
        ))}
    </Box>
  );
};

const SessionMetrics: FC<SessionsViewProps> = ({ filter }) => {
  const ranges: Array<TimeRangeOption> = ["1W", "1M", "3M", "1Y", "ALL"];

  const [rangeIndex, setRangeIndex] = useState(0);
  const handleRangeChange = (newValue: number) => {
    setRangeIndex(newValue);
  };

  return (
    <Card>
      <CardContent>
        <Box display="flex" width="100%" marginBottom={2}>
          <Box display="flex" width="100%">
            <Typography variant="h3" align="left">
              <FormattedMessage id="metrics" />
            </Typography>
          </Box>
          <TimeRangePicker options={ranges} selected={rangeIndex} onChange={handleRangeChange} />
        </Box>
        <SimpleTabContent
          tabs={ranges.map((range) => ({
            key: range,
            child: <SessionMetricGroup range={range} filter={filter} />,
          }))}
          selected={rangeIndex}
        />
      </CardContent>
    </Card>
  );
};

export default SessionMetrics;
