export type TimeRange = "DAY" | "WEEK" | "MONTH" | "THREE_MONTH" | "YEAR" | "ALL";

export type TimeBlockSize = "HOUR" | "DAY";

interface SessionFilter {
  tenantId: string;
  locationId?: string;
  stationId?: string;
}

export interface SessionMetricsFilter extends SessionFilter {
  timeRange: TimeRange;
}

interface TimeSeriesFilter {
  from: string;
  until?: string;
  timezone: string;
  timeBlockSize: TimeBlockSize;
}

export interface SessionTimeSeriesFilter extends SessionFilter, TimeSeriesFilter {}

export interface SessionMetricsResponse {
  activeSessions: SessionMetricsValue;
  totalSessions: SessionMetricsValue;
  totalPower: SessionMetricsValue;
  totalRevenue: SessionMetricsValue;
  currency?: string;
}

export interface SessionMetricsValue {
  value: number;
  report?: number;
  status?: string;
}

export const emptySessionMetricsResponse: SessionMetricsResponse = {
  activeSessions: { value: 0 },
  totalSessions: { value: 0 },
  totalPower: { value: 0 },
  totalRevenue: { value: 0 },
  currency: undefined,
};

export type SessionChartResponse = Array<SessionChartValue>;

export interface SessionChartValue {
  dateTime: string;
  value: number;
}

export type TimeSeries = {
  items: Array<TimeSeriesItem>;
};

export type TimeSeriesItem = {
  dateTime: string;
  value: number;
};

export interface SessionExportParams {
  timezone: string;
  startTimeFrom: string;
  startTimeTo: string;
  tenantId: string;
  locationId?: string;
  stationId?: string;
  rfid?: string;
}
