import dayjs from "dayjs";
import { TimeRange } from "src/api/sessions/interfaces";
import { TimeRangeOption } from "../TimeRangePicker";

export const rangeMapping: { [key: string]: TimeRange } = {
  "1D": "DAY",
  "1W": "WEEK",
  "1M": "MONTH",
  "3M": "THREE_MONTH",
  "1Y": "YEAR",
  ALL: "ALL",
};

export function getFromDateByRange(range: TimeRangeOption) {
  if (range === "1D") return dayjs().subtract(1, "d").toISOString();
  if (range === "1W") return dayjs().subtract(6, "d").toISOString();
  return dayjs().subtract(1, "M").toISOString();
}

export function getTimeBlockSizeByRange(range: TimeRangeOption) {
  if (range === "1D") return "HOUR";
  return "DAY";
}
