import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { FC, useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthStatus } from "src/api/auth/interfaces";
import UserContext from "src/components/UserContext";
import Header from "src/components/layout/Header";
import Sidebar from "src/components/layout/Sidebar";
import { getAuthStatus } from "src/services/auth";

const ProtectedRoute: FC = observer(() => {
  const user = useContext(UserContext);

  const authStatus = getAuthStatus();
  if (authStatus === AuthStatus.Invalid) {
    return <Navigate to="/login" />;
  }
  if (authStatus === AuthStatus.ResetPassword) {
    return <Navigate to="/reset-password" />;
  }

  // TODO overflowX is a temporary fix to eliminate horizontal scrolling on smaller resolutions
  return (
    <Box display="flex" key={user.activeTenant.id}>
      <Sidebar />
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        padding={3}
        maxWidth={1200}
        style={{ overflowX: "hidden" }}
      >
        <Header />
        <Box display="flex" width="100%" paddingTop={3}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
});

export default ProtectedRoute;
