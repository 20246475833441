import { Box } from "@mui/material";
import { FC } from "react";

const computeColor = (percentage: number) => {
  const r1 = 245;
  const g1 = 245;
  const b1 = 245;
  const a1 = 1;
  const r2 = 135;
  const g2 = 0;
  const b2 = 255;
  const a2 = 1;
  const r = (1 - percentage) * r1 + percentage * r2;
  const g = (1 - percentage) * g1 + percentage * g2;
  const b = (1 - percentage) * b1 + percentage * b2;
  const a = (1 - percentage) * a1 + percentage * a2;
  return `rgba(${r}, ${g}, ${b}, ${a})`;
};

interface HeatMapBlockProps {
  intensity: number;
}

const HeatMapBlock: FC<HeatMapBlockProps> = ({ intensity }) => {
  const intensityWithCeiling = intensity > 1 ? 1 : intensity;
  return (
    <Box
      sx={{
        height: "24px",
        width: "24px",
        position: "relative",
        borderRadius: "2px",
        background: "hsl(196deg 36% 95%)",
      }}
      style={{ backgroundColor: computeColor(intensityWithCeiling) }}
    />
  );
};

export default HeatMapBlock;
