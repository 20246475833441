import { Button, Grid, Slider, Typography } from "@mui/material";
import { FC, useState } from "react";
import { useIntl } from "react-intl";
import { EvseDetailsResponse } from "src/api/stations/interface";
import useUpdateChargingProfile from "src/hooks/useUpdateEvseChargingProfile";

const EvseCardSlider: FC<{ stationId: string; evse: EvseDetailsResponse }> = ({ stationId, evse }) => {
  const isAc = evse.specifications.powerType.split("_")[0] === "AC";
  const unit = isAc ? "A" : "kW";
  const minValue = 0;
  const maxValue = isAc ? evse.specifications.powerMaxCurrent : evse.specifications.powerMaxWattage / 1000;
  const label = isAc ? "assignedCurrentXxxYyy" : "assignedPowerXxxYyy";
  const [assignedLimit, setAssignedLimit] = useState(maxValue);
  const intl = useIntl();
  const { mutate } = useUpdateChargingProfile(intl, stationId, evse.id);

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography>{intl.formatMessage({ id: label }, { xxx: assignedLimit, yyy: unit })}</Typography>
      </Grid>

      <Grid container item spacing={3} alignItems="center">
        <Grid item xs>
          <Slider
            value={assignedLimit}
            onChange={(_, value) => setAssignedLimit(value as number)}
            valueLabelDisplay="auto"
            defaultValue={assignedLimit}
            min={minValue}
            max={maxValue}
            marks={[
              { value: minValue, label: minValue },
              { value: maxValue, label: maxValue },
            ]}
          />
        </Grid>

        <Grid item>
          <Button
            variant="outlined"
            onClick={() =>
              mutate({
                unit: isAc ? "A" : "W",
                limit: isAc ? assignedLimit : assignedLimit * 1000,
              })
            }
          >
            {intl.formatMessage({ id: "apply" })}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EvseCardSlider;
