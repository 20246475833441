import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { FC, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import PublicPage from "src/components/layout/PublicPage";
import { notifyError } from "src/components/notifications";
import { UserFormValues, initialValues, loginValidation } from "src/pages/users/schema";
import { login } from "src/services/auth";

const Login: FC = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const pageTitle = intl.formatMessage({ id: "signIn" });

  const onSubmit = async (params: UserFormValues) => {
    const result = await login({
      email: params.email,
      password: params.password,
    });

    if (result.errId) {
      notifyError(result.errId, intl);
    } else if (result.changePasswordRequired) {
      navigate("/reset-password");
    } else {
      navigate("/");
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: loginValidation,
    onSubmit,
  });

  return (
    <PublicPage title={pageTitle}>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="100%">
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Typography variant="h2" align="center" marginTop="10px" marginBottom="30px">
            <FormattedMessage id="logIn" />
          </Typography>
          <Typography variant="body2" align="center" marginBottom="50px">
            <FormattedMessage id="signInText" />
          </Typography>
        </Box>

        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            id="email"
            name="email"
            label={intl.formatMessage({ id: "email" })}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email && intl.formatMessage({ id: formik.errors.email })}
          />

          <FormControl fullWidth>
            <InputLabel htmlFor="password">
              <FormattedMessage id="password" />
            </InputLabel>
            <OutlinedInput
              id="password"
              label="password"
              type={showPassword ? "text" : "password"}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText>
              {formik.touched.password && formik.errors.password && intl.formatMessage({ id: formik.errors.password })}
            </FormHelperText>
          </FormControl>

          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            sx={{
              marginTop: "60px",
            }}
          >
            <FormattedMessage id="continue" />
          </Button>
        </form>
      </Box>
    </PublicPage>
  );
};

export default Login;
