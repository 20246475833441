import { LoaderStatus } from "@googlemaps/js-api-loader";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { getLocationSummaries } from "src/api/locations";
import { LocationSummaryResponse } from "src/api/locations/interfaces";
import ClusteredMap from "../googleMaps/ClusteredMap";
import GoogleLibLoader from "../googleMaps/GoogleLibLoader";
import { notifyAxiosError } from "../notifications";
import { LocationsViewProps } from "./interfaces";

interface LocationInfoWindowProps {
  location: LocationSummaryResponse;
  nameLabel: string;
  addressLabel: string;
  stationsLabel: string;
  evsesLabel: string;
}

const LocationInfoWindow: FC<LocationInfoWindowProps> = ({
  location,
  nameLabel,
  addressLabel,
  stationsLabel,
  evsesLabel,
}) => (
  <Box>
    <Typography variant="body1" style={{ fontWeight: "bold", display: "inline" }}>
      {nameLabel}
    </Typography>
    <Typography variant="body1" style={{ display: "inline" }}>
      {location.name}
    </Typography>
    <br />
    <Typography variant="body1" style={{ fontWeight: "bold", display: "inline" }}>
      {addressLabel}
    </Typography>
    <Typography variant="body1" style={{ display: "inline" }}>
      {location.address}
    </Typography>
    <br />
    <Typography variant="body1" style={{ fontWeight: "bold", display: "inline" }}>
      {stationsLabel}
    </Typography>
    <Typography variant="body1" style={{ display: "inline" }}>
      {location.stations.total}
    </Typography>
    <br />
    <Typography variant="body1" style={{ fontWeight: "bold", display: "inline" }}>
      {evsesLabel}
    </Typography>
    <Typography variant="body1" style={{ display: "inline" }}>
      {location.evses.total}
    </Typography>
  </Box>
);

const LocationsMap: FC<LocationsViewProps> = ({ filter }) => {
  const intl = useIntl();
  const [, setLocationData] = useState<LocationSummaryResponse[]>([]);
  const [locations, setLocations] = useState<LocationSummaryResponse[]>([]);
  const [pins, setPins] = useState<google.maps.LatLngLiteral[]>([]);
  const [googleMapsLoadingStatus, setGoogleMapsLoadingStatus] = useState<LoaderStatus>(LoaderStatus.INITIALIZED);
  useEffect(() => {
    getLocationSummaries({ ...filter })
      .then((response) => {
        setLocationData(response.data);
        setPins(
          response.data.map(
            (location): google.maps.LatLngLiteral => ({
              lat: location.latitude,
              lng: location.longitude,
            }),
          ),
        );
        setLocations(response.data);
      })
      .catch((err) => notifyAxiosError(err, intl));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nameLabel = intl.formatMessage({ id: "nameLabel" });
  const addressLabel = intl.formatMessage({ id: "addressLabel" });
  const stationsLabel = intl.formatMessage({ id: "stationsLabel" });
  const evsesLabel = intl.formatMessage({ id: "evsesLabel" });

  return (
    <>
      <GoogleLibLoader setLoadingStatus={setGoogleMapsLoadingStatus} />
      <Card>
        <CardContent>
          <Typography variant="h3" mb="15px">
            <FormattedMessage id="map" />
          </Typography>
          <Box height={276} width="100%">
            <ClusteredMap
              loaderStatus={googleMapsLoadingStatus}
              pins={pins}
              popUps={locations.map((location) => (
                <LocationInfoWindow
                  key={`${location.id}-map-popup`}
                  location={location}
                  nameLabel={nameLabel}
                  addressLabel={addressLabel}
                  stationsLabel={stationsLabel}
                  evsesLabel={evsesLabel}
                />
              ))}
            />
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default LocationsMap;
