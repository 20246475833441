import CircleIcon from "@mui/icons-material/Circle";
import { Dayjs } from "dayjs";
import { FC } from "react";
import { statusColors } from "src/theme/colors";
import StatusInfoTooltip from "../StatusInfoTooltip";

interface StatusIconProps {
  status: string;
  statusOcpp: string;
  statusUpdatedAt: Dayjs;
}

const StatusIcon: FC<StatusIconProps> = ({ status, statusOcpp, statusUpdatedAt }) => (
  <StatusInfoTooltip status={status} statusOcpp={statusOcpp} statusUpdatedAt={statusUpdatedAt}>
    <CircleIcon fontSize="small" sx={{ color: statusColors.get(status.toLowerCase()) }} />
  </StatusInfoTooltip>
);

export default StatusIcon;
