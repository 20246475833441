import { LoaderStatus } from "@googlemaps/js-api-loader";
import { FC, useEffect, useRef, useState } from "react";
import greyScaleMapStyle from "./map-styles";

interface GoogleMapParams {
  loaderStatus: LoaderStatus;
  coords: google.maps.LatLngLiteral;
  setCoords: (data: google.maps.LatLngLiteral) => void;
  zoom: number;
}

const GoogleMap: FC<GoogleMapParams> = ({ loaderStatus, coords, setCoords, zoom }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [map, setMap] = useState<google.maps.Map>();
  const [marker, setMarker] = useState<google.maps.Marker>();

  const onClick = (e: google.maps.MapMouseEvent) => {
    if (e.latLng) {
      setCoords(e.latLng.toJSON());
    }
  };

  useEffect(() => {
    if (loaderStatus === LoaderStatus.SUCCESS && ref.current && !map) {
      const newMap = new window.google.maps.Map(ref.current, {
        disableDefaultUI: true,
        fullscreenControl: true,
        zoomControl: true,
        styles: greyScaleMapStyle,
      });
      newMap.addListener("click", onClick);
      setMap(newMap);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaderStatus, ref, map]);

  useEffect(() => {
    if (map) {
      if (!marker) {
        setMarker(
          new google.maps.Marker({
            position: coords,
            map,
            icon: "/maps/marker.svg",
          }),
        );
      } else {
        marker.setPosition(coords);
      }
      map.panTo(coords);
    }
  }, [map, marker, coords]);

  useEffect(() => {
    if (map) {
      map.setZoom(zoom);
    }
  }, [map, zoom]);

  return <div ref={ref} id="map" style={{ width: "100%", height: "100%", borderRadius: "12px" }} />;
};

export default GoogleMap;
