import { Box, Typography, useTheme } from "@mui/material";
import { FC } from "react";

const HeatMapLegend: FC<{}> = () => {
  const { palette } = useTheme();
  return (
    <Box maxWidth="220px" ml="auto">
      {/* Gradient visualization */}
      <Box
        sx={{
          height: "15px",
          width: "100%",
          borderRadius: "2px",
          background: `linear-gradient(90deg, ${palette.grey[100]} 0%, #8700FF 100%)`,
        }}
      />

      {/* Labels */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "5px",
          fontSize: "12px",
          color: palette.grey[500],
          opacity: "0.6",
        }}
      >
        <Typography
          fontSize="12px"
          color={palette.grey[500]}
          sx={{
            opacity: "0.6",
          }}
        >
          0%
        </Typography>

        <Typography
          fontSize="12px"
          color={palette.grey[500]}
          sx={{
            opacity: "0.6",
          }}
        >
          100%
        </Typography>
      </Box>
    </Box>
  );
};

export default HeatMapLegend;
