import { Box, Card, CardContent } from "@mui/material";
import { FC, ReactNode } from "react";
import { Helmet } from "react-helmet-async";
import { ReactComponent as Logo } from "src/assets/images/logos/eigen-logo.svg";

interface PageProps {
  children: ReactNode;
  title: string;
}

const PublicPage: FC<PageProps> = ({ children, title }) => (
  <>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    <Box display="flex" flexDirection="column" width="100%" height="100%">
      <Box margin="50px 40px">
        <Logo />
      </Box>
      <Box display="flex" width="100%" height="100%">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          margin="auto"
          maxWidth="440px"
        >
          <Card>
            <CardContent>{children}</CardContent>
          </Card>
        </Box>
      </Box>
    </Box>
  </>
);

export default PublicPage;
