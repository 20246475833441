import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { FC, useState } from "react";
import useDebugStationAction from "src/hooks/debug/useDebugStationAction";
import { notifyErrorRaw } from "../notifications";
import { parseOptionalNumber } from "../parse-utilities";

const messageTypes = [
  "BootNotification",
  "DiagnosticsStatusNotification",
  "FirmwareStatusNotification",
  "Heartbeat",
  "MeterValues",
  "StatusNotification",
];

const TriggerMessage: FC<{ stationId: string }> = ({ stationId }) => {
  const [messageType, setMessageType] = useState<string>("");
  const [connectorId, setConnectorId] = useState<number | undefined>();
  const { mutate } = useDebugStationAction(stationId);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        if (stationId)
          mutate({
            method: "POST",
            url: `trigger/${messageType}`,
            params: connectorId !== undefined && connectorId !== null ? { connector: connectorId } : undefined,
          });
        else notifyErrorRaw("Station ID is required");
      }}
    >
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs>
          <FormControl size="small" fullWidth required>
            <InputLabel>messageType</InputLabel>
            <Select label="messageType" value={messageType} onChange={(event) => setMessageType(event.target.value)}>
              {messageTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs>
          <TextField
            label="connectorId"
            type="number"
            size="small"
            fullWidth
            onChange={(event) => setConnectorId(parseOptionalNumber(event.target.value))}
          />
        </Grid>

        <Grid item xs>
          <Button variant="contained" type="submit">
            TRIGGER MESSAGE
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default TriggerMessage;
