import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthStatus } from "src/api/auth/interfaces";
import { getAuthStatus } from "src/services/auth";

const RestrictedRoute: FC = observer(() => {
  const authStatus = getAuthStatus();

  if (authStatus === AuthStatus.Invalid) {
    return <Navigate to="/login" />;
  }
  if (authStatus === AuthStatus.Valid) {
    return <Navigate to="/" />;
  }

  return (
    <Box
      sx={{
        bgcolor: "black",
        display: "flex",
        height: "100vh",
        minHeight: "700px",
        overflow: "hidden",
        width: "100%",
      }}
    >
      <Box
        sx={{
          bgcolor: "background.default",
          display: "flex",
          flex: "1 1 auto",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          overflow: "auto",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
});

export default RestrictedRoute;
