import { Visibility, VisibilityOff } from "@mui/icons-material";
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { useFormikContext } from "formik";
import { FC, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

interface PasswordFieldParams {
  id: string;
  name: string;
  label: string;
  value: string | undefined;
  touched: boolean | undefined;
  error: string | undefined;
}

const PasswordField: FC<PasswordFieldParams> = ({ id, name, label, value, touched, error }) => {
  const intl = useIntl();
  const { handleChange, handleBlur } = useFormikContext();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <FormControl fullWidth>
      <InputLabel
        // shrink
        htmlFor={id}
      >
        <FormattedMessage id={label} />
      </InputLabel>
      <OutlinedInput
        id={id}
        autoComplete={id}
        name={name}
        label={label}
        type={showPassword ? "text" : "password"}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched && Boolean(error)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
      <FormHelperText>{touched && error && intl.formatMessage({ id: error })}</FormHelperText>
    </FormControl>
  );
};

export default PasswordField;
