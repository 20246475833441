import { DesktopTimePicker, TimePickerProps } from "@mui/x-date-pickers-pro";
import { Dayjs } from "dayjs";
import { Dispatch, FC, SetStateAction, useState } from "react";

type TariffsTimePickerProps = {
  label: string;
  parkingException: Dayjs;
  setParkingException: Dispatch<SetStateAction<Dayjs>>;
  restrictedTime?: Dayjs;
};

export const ParkingExceptionTimePicker: FC<TariffsTimePickerProps> = ({
  label,
  parkingException,
  setParkingException,
  restrictedTime,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const shouldDisableTime: TimePickerProps<Dayjs>["shouldDisableTime"] = (value, view) => {
    if (view === "seconds") return false;
    if (restrictedTime) {
      return value.hour() === restrictedTime.hour() && value.minute() === restrictedTime.minute();
    }
    return false;
  };

  return (
    <DesktopTimePicker
      shouldDisableTime={restrictedTime ? shouldDisableTime : undefined}
      label={label}
      value={parkingException}
      slotProps={{
        textField: {
          fullWidth: true,
          onClick: () => {
            setIsOpen(!isOpen);
          },
        },
        field: {
          readOnly: true,
        },
      }}
      sx={{
        marginRight: 2,
      }}
      timeSteps={{ minutes: 30 }}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      open={isOpen}
      onChange={(newTime) => {
        if (newTime == null) return;
        setParkingException(newTime);
      }}
    />
  );
};

export default ParkingExceptionTimePicker;
