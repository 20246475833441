import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { IntlShape } from "react-intl";
import { axiosInstance } from "src/api";
import { notifyError, notifySuccess } from "src/components/notifications";

const useTerminateSession = (intl: IntlShape) =>
  useMutation({
    mutationKey: ["terminateSession"],
    mutationFn: (id: string) => axiosInstance.put<string>(`/sessions/terminate/${id}`),
    onSuccess: () => notifySuccess("stopEvse", intl),
    onError: (error) => notifyError((error as AxiosError).code!, intl),
  });

export default useTerminateSession;
