import { Box, Tab, Tabs } from "@mui/material";
import { FC, SyntheticEvent } from "react";
import { useIntl } from "react-intl";

export interface SimpleTabsProps {
  options: Array<string>;
  selected: number;
  onChange: (event: SyntheticEvent, newValue: number) => void;
}

const SimpleTabs: FC<SimpleTabsProps> = ({ options, selected, onChange }) => {
  const intl = useIntl();

  return (
    <Box display="flex" flexDirection="row" flexGrow={1} justifyContent="flex-beginning">
      <Tabs value={selected} onChange={onChange}>
        {options.map((item) => (
          <Tab key={item} label={intl.formatMessage({ id: item })} />
        ))}
      </Tabs>
    </Box>
  );
};

export default SimpleTabs;
