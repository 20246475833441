import { Box, Button, Card, CardContent, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import { observer } from "mobx-react-lite";
import { FC, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { postChangePassword } from "src/api/auth";
import UserContext from "src/components/UserContext";
import PasswordField from "src/components/forms/PasswordField";
import Page from "src/components/layout/Page";
import { notifyAxiosError } from "src/components/notifications";
import { UserFormValues, changePasswordValidation, initialValues } from "src/pages/users/schema";

const UserEmail = observer(() => {
  const intl = useIntl();
  const user = useContext(UserContext);

  return (
    <TextField
      disabled
      fullWidth
      id="email"
      label={intl.formatMessage({ id: "email" })}
      value={user.activeUserProfile.email}
      InputLabelProps={{ shrink: true }}
    />
  );
});

const UserPasswordChange: FC = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  const pageTitle = intl.formatMessage({ id: "changePassword" });

  const onSubmit = (params: UserFormValues) => {
    postChangePassword({
      password: params.oldPassword,
      newPassword: params.password,
    })
      .then(() => navigate("/"))
      .catch((err) => notifyAxiosError(err, intl));
  };

  return (
    <Page title={pageTitle} breadcrumbs={[]}>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" margin="auto">
        <Card
          sx={{
            width: "440px",
          }}
        >
          <CardContent>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Typography variant="h2" align="center" marginTop="10px" marginBottom="30px">
                  <FormattedMessage id="changePassword" />
                </Typography>
              </Box>

              <Formik initialValues={initialValues} validationSchema={changePasswordValidation} onSubmit={onSubmit}>
                {(formik) => (
                  <form onSubmit={formik.handleSubmit}>
                    <UserEmail />
                    <PasswordField
                      id="current-password"
                      name="oldPassword"
                      label="passwordCurrent"
                      value={formik.values.oldPassword}
                      touched={formik.touched.oldPassword}
                      error={formik.errors.oldPassword}
                    />
                    <PasswordField
                      id="new-password"
                      name="password"
                      label="password"
                      value={formik.values.password}
                      touched={formik.touched.password}
                      error={formik.errors.password}
                    />
                    <PasswordField
                      id="confirm-new-password"
                      name="confirmPassword"
                      label="confirmPassword"
                      value={formik.values.confirmPassword}
                      touched={formik.touched.confirmPassword}
                      error={formik.errors.confirmPassword}
                    />
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="submit"
                      sx={{
                        marginTop: "60px",
                      }}
                    >
                      <FormattedMessage id="continue" />
                    </Button>
                  </form>
                )}
              </Formik>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Page>
  );
};

export default UserPasswordChange;
