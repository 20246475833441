import { Box, Breadcrumbs, Link, Typography, useTheme } from "@mui/material";
import { FC, ReactNode } from "react";
import { Helmet } from "react-helmet-async";
import { FormattedMessage } from "react-intl";

interface BreadCrumb {
  title: string;
  titleLiteral?: boolean;
  link?: string;
}

interface PageProps {
  children: ReactNode;
  title: string;
  breadcrumbs: Array<BreadCrumb>;
}

const Page: FC<PageProps> = ({ children, title, breadcrumbs }) => {
  const { palette } = useTheme();
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Breadcrumbs
          sx={{ marginBottom: 2 }}
          separator={
            <Typography variant="h6" color={palette.text.primary}>
              /
            </Typography>
          }
        >
          {breadcrumbs.map((item) => {
            const text = item.titleLiteral ? item.title : <FormattedMessage id={item.title} />;
            return item.link ? (
              <Link key={item.title} underline="hover" href={item.link}>
                <Typography key={item.title} variant="h6">
                  {text}
                </Typography>
              </Link>
            ) : (
              <Typography key={item.title} variant="h6" color={palette.primary.light}>
                {text}
              </Typography>
            );
          })}
        </Breadcrumbs>
        {children}
      </Box>
    </>
  );
};

export default Page;
