import { createTheme } from "@mui/material";
import typography from "./typography";

const theme = createTheme({
  typography,
  palette: {
    primary: {
      light: "#ACC0D2",
      main: "#36597D",
      dark: "#13406C",
      contrastText: "#fff",
    },
    secondary: {
      light: "#4BAF47",
      main: "#1da868",
      dark: "#009C9F",
      contrastText: "#fff",
    },
    action: {
      hover: "#f3f5fB",
      selected: "#dbdff1",
    },
    info: {
      // light: '#58abe7', // Not used
      light: "#D7E1EA",
      main: "#2F96e1",
      dark: "#20699d",
      contrastText: "#fff",
    },
    success: {
      light: "#33c753",
      main: "#00b929",
      dark: "#00811c",
      contrastText: "#fff",
    },
    warning: {
      light: "#ffc787",
      main: "#ffbA69",
      dark: "#b28249",
      contrastText: "#fff",
    },
    error: {
      light: "#FCEDF1",
      main: "#E1345E",
      dark: "#6B1026",
      contrastText: "#fff",
    },
    background: {
      default: "#F2F5FA",
      paper: "#fff",
    },
    text: {
      primary: "#36597D",
      secondary: "#000",
    },
    common: {
      white: "#fff",
      black: "#2E3338",
    },
    grey: {
      100: "#F5F5F5",
      200: "#E6E6E6",
      300: "#C7C7C7",
      400: "#A8A8A8",
      500: "#808080",
      600: "#454D54",
      700: "#2E3338",
    },
  },
  components: {
    MuiCard: {
      defaultProps: {
        elevation: 4,
        sx: {
          borderRadius: "12px",
          padding: "8px",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        sx: {
          marginY: "12px",
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        sx: {
          marginY: "12px",
        },
      },
    },
  },
});

export default theme;
