import { Button, Grid, TextField } from "@mui/material";
import { FC, useState } from "react";
import useDebugStationAction from "src/hooks/debug/useDebugStationAction";
import { notifyErrorRaw } from "../notifications";

const UnlockConnector: FC<{ stationId: string }> = ({ stationId }) => {
  const [connectorId, setConnectorId] = useState("");
  const { mutate } = useDebugStationAction(stationId);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        if (stationId) mutate({ method: "POST", url: `connector/${connectorId}/unlock` });
        else notifyErrorRaw("Station ID is required");
      }}
    >
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <TextField
            label="connectorId"
            size="small"
            required
            onChange={(event) => setConnectorId(event.target.value)}
          />
        </Grid>

        <Grid item>
          <Button variant="contained" type="submit">
            UNLOCK CONNECTOR
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default UnlockConnector;
