import { Box, Grid } from "@mui/material";
import { FC } from "react";
import { useIntl } from "react-intl";
import Page from "src/components/layout/Page";
import TariffsTable from "src/components/tariffs/TariffsTable";

const Tariffs: FC = () => {
  const intl = useIntl();
  const pageTitle = intl.formatMessage({ id: "tariffs" });

  return (
    <Page title={pageTitle} breadcrumbs={[]}>
      <Box sx={{ width: "100%" }}>
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={12} lg={12}>
            <TariffsTable />
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export default Tariffs;
