import { axiosInstance } from "src/api";
import { RfidFilter, RfidResponse } from "src/api/rfid/interfaces";

interface PageResponse<T> {
  content: Array<T>;

  // TODO define remaining pagination fields
  totalElements: number;
}

const getRfids = (filter: RfidFilter) =>
  axiosInstance.get<PageResponse<RfidResponse>>("/rfid", {
    params: {
      ...filter,
      size: 999,
    },
  });

export default getRfids;
