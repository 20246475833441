import { Button, Grid, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers-pro";
import { Dayjs } from "dayjs";
import { FC, useState } from "react";
import useDebugStationAction from "src/hooks/debug/useDebugStationAction";
import { notifyErrorRaw } from "../notifications";
import { parseOptionalNumber } from "../parse-utilities";

const UpdateFirmware: FC<{ stationId: string }> = ({ stationId }) => {
  const [url, setUrl] = useState("");
  const [retries, setRetries] = useState<number | undefined>();
  const [retryDelay, setRetryDelay] = useState<number | undefined>();
  const [after, setAfter] = useState<Dayjs | undefined>();
  const { mutate } = useDebugStationAction(stationId);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        if (stationId)
          mutate({
            method: "POST",
            url: "firmware",
            body: {
              url,
              retries,
              retry_delay: retryDelay === undefined || retryDelay === null ? undefined : `${retryDelay}s`,
              after,
            },
          });
        else notifyErrorRaw("Station ID is required");
      }}
    >
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <TextField label="url" size="small" required value={url} onChange={(event) => setUrl(event.target.value)} />
        </Grid>

        <Grid item>
          <TextField
            label="retries"
            type="number"
            size="small"
            onChange={(event) => setRetries(parseOptionalNumber(event.target.value))}
          />
        </Grid>

        <Grid item>
          <TextField
            label="retryDelay"
            type="number"
            size="small"
            onChange={(event) => setRetryDelay(parseOptionalNumber(event.target.value))}
          />
        </Grid>

        <Grid item>
          <DateTimePicker
            label="after"
            views={["year", "month", "day", "hours", "minutes", "seconds"]}
            slotProps={{ textField: { size: "small" } }}
            onChange={(value) => setAfter(value as Dayjs)}
          />
        </Grid>

        <Grid item>
          <Button variant="contained" type="submit">
            UPDATE FIRMWARE
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default UpdateFirmware;
