import { Palette } from "@mui/material";
import {
  ChartDataset,
  CoreChartOptions,
  DatasetChartOptions,
  ElementChartOptions,
  LineControllerChartOptions,
  PluginChartOptions,
  ScaleChartOptions,
} from "chart.js";
import { _DeepPartialObject } from "chart.js/dist/types/utils";

/**
 * Returns a base object that can be used as the options attribute of a Chart.js Line component.
 * @param palette The current theme's palette.
 * @param xAxisMaxTicksLimit The maximum number of ticks to be displayed on the x-axis.
 * @returns A valid options attribute for a Chart.js Line component.
 */
export function getBaseLineChartOptions(
  palette: Palette,
  xAxisMaxTicksLimit: number | undefined,
): _DeepPartialObject<
  CoreChartOptions<"line"> &
    ElementChartOptions<"line"> &
    PluginChartOptions<"line"> &
    DatasetChartOptions<"line"> &
    ScaleChartOptions<"line"> &
    LineControllerChartOptions
> {
  return {
    interaction: {
      intersect: false,
      mode: "index",
    },
    animation: {
      duration: 0,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: palette.background.paper,
        borderColor: palette.grey[500],
        borderWidth: 0.5,
        bodyColor: palette.text.secondary,
        titleColor: palette.text.secondary,
        usePointStyle: true,
        boxPadding: 2,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          autoSkip: true,
          maxTicksLimit: xAxisMaxTicksLimit,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          padding: 4,
          precision: 0,
        },
      },
    },
  };
}

/**
 * Returns a base object that can be used as the dataset attribute of a Chart.js Line component.
 * @returns The default styles to be used for the dataset attribute for a Chart.js Line component.
 */
export function getBaseLineChartDataset(): _DeepPartialObject<ChartDataset<"line", number[]>> {
  return {
    backgroundColor: "#DDFAEB",
    fill: true,
    borderColor: "#33EEAA",
    borderWidth: 2,
    pointRadius: 0,
    pointHoverRadius: 7,
    pointHoverBackgroundColor: "#33EEAA",
  };
}
