// eslint-disable-next-line import/no-anonymous-default-export
export default {
  fontFamily: ["Roboto", "Poppins", "sans-serif"].join(","),
  h1: {
    fontFamily: "Roboto",
    fontWeight: 700,
    fontSize: 60,
    letterSpacing: 0.15,
  },
  h2: {
    fontFamily: "Roboto",
    fontWeight: 600,
    fontSize: 24,
    letterSpacing: 0.15,
  },
  h3: {
    fontFamily: "Roboto",
    fontWeight: 600,
    fontSize: 20,
    letterSpacing: 0.15,
  },
  h4: {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: 0.15,
  },
  h5: {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: 16,
    letterSpacing: 0,
  },
  h6: {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: 0.3,
  },
  subtitle1: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: 20,
    letterSpacing: 0.44,
  },
  body1: {
    fontWeight: 400,
    fontSize: 16,
    letterSpacing: 0,
  },
  body2: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: 14,
    letterSpacing: 0.44,
  },
  caption: {
    fontWeight: 400,
    fontSize: 12,
    letterSpacing: 0.4,
  },
  overline: {
    fontWeight: 700,
    fontSize: 10,
    letterSpacing: "-1.5",
  },
  fontWeight: {
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    black: 900,
  },
};
