import { Typography, useTheme } from "@mui/material";
import { FC } from "react";

interface HeatMapYAxisLabelProps {
  label: string;
}

const HeatMapYAxisLabel: FC<HeatMapYAxisLabelProps> = ({ label }) => {
  const { palette } = useTheme();
  return (
    <Typography
      sx={{
        textAlign: "right",
        fontSize: "14px",
        paddingRight: "8px",
        color: palette.grey[500],
        display: "block",
        width: "auto",
        minWidth: "40px",
        opacity: "0.6",
        height: "15px",
      }}
    >
      {label}
    </Typography>
  );
};

export default HeatMapYAxisLabel;
