import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { FC, useState } from "react";
import useDebugStationAction from "src/hooks/debug/useDebugStationAction";
import { notifyErrorRaw } from "../notifications";

const certificateUseTypes = ["central_system_root_certificate", "manufacturer_root_certificate"];

const InstallCertificate: FC<{ stationId: string }> = ({ stationId }) => {
  const [certificateUseType, setCertificateUseType] = useState("");
  const [certificate, setCertificate] = useState("");
  const { mutate } = useDebugStationAction(stationId);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        if (stationId)
          mutate({
            method: "POST",
            url: "install_certificate",
            body: { certificate, certificate_type: certificateUseType },
          });
        else notifyErrorRaw("Station ID is required");
      }}
    >
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={4}>
          <FormControl size="small" fullWidth required>
            <InputLabel>certificateUseType</InputLabel>
            <Select
              label="certificateUseType"
              value={certificateUseType}
              onChange={(event) => setCertificateUseType(event.target.value)}
            >
              {certificateUseTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <TextField
            label="certificate"
            size="small"
            required
            multiline
            onChange={(event) => setCertificate(event.target.value)}
          />
        </Grid>

        <Grid item>
          <Button variant="contained" type="submit">
            INSTALL CERTIFICATE
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default InstallCertificate;
