import { Navigate, RouteObject } from "react-router-dom";
import Dashboard from "src/pages/Dashboard";
import DeveloperTools from "src/pages/DeveloperTools/DeveloperTools";
import LocationDetails from "src/pages/LocationDetails";
import LocationEdit from "src/pages/LocationEdit";
import Locations from "src/pages/Locations";
import NotFound from "src/pages/NotFound";
import Reports from "src/pages/Reports";
import Sessions from "src/pages/Sessions";
import StationDetails from "src/pages/StationDetails";
import StationEdit from "src/pages/StationEdit";
import Stations from "src/pages/Stations";
import TariffDetails from "src/pages/TariffDetails";
import TariffEdit from "src/pages/TariffEdit";
import Tariffs from "src/pages/Tariffs";
import Login from "src/pages/users/UserLogin";
import UserPasswordChange from "src/pages/users/UserPasswordChange";
import UserPasswordReset from "src/pages/users/UserPasswordReset";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import RestrictedRoute from "./RestrictedRoute";

const routes: RouteObject[] = [
  {
    path: "/",
    element: <ProtectedRoute />,
    children: [
      {
        path: "/",
        element: <Navigate to="/dashboard" />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "locations",
        element: <Locations />,
      },
      {
        path: "locations/:id",
        element: <LocationDetails />,
      },
      {
        path: "locations/form/:action",
        element: <LocationEdit />,
      },
      {
        path: "locations/form/:action/:id",
        element: <LocationEdit />,
      },
      {
        path: "stations",
        element: <Stations />,
      },
      {
        path: "stations/:id",
        element: <StationDetails />,
      },
      {
        path: "stations/form/:action",
        element: <StationEdit />,
      },
      {
        path: "stations/form/:action/:id",
        element: <StationEdit />,
      },
      {
        path: "sessions",
        element: <Sessions />,
      },
      {
        path: "tariffs",
        element: <Tariffs />,
      },
      {
        path: "tariffs/:id",
        element: <TariffDetails />,
      },
      {
        path: "tariffs/form/:action",
        element: <TariffEdit />,
      },
      {
        path: "tariffs/form/:action/:id",
        element: <TariffEdit />,
      },
      {
        path: "reports",
        element: <Reports />,
      },
      {
        path: "users/form/password",
        element: <UserPasswordChange />,
      },
      {
        path: "developer",
        element: <DeveloperTools />,
      },
      {
        path: "404",
        element: <NotFound />,
      },
    ],
  },
  {
    path: "/",
    element: <PublicRoute />,
    children: [
      {
        path: "login",
        element: <Login />,
      },
    ],
  },
  {
    path: "/",
    element: <RestrictedRoute />,
    children: [
      {
        path: "reset-password",
        element: <UserPasswordReset />,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/404" />,
  },
];

export default routes;
