import { Grid, SvgIcon, Typography, useTheme } from "@mui/material";
import { FC } from "react";
import { EvseDetailsResponse } from "src/api/stations/interface";
import { ReactComponent as TariffsIcon } from "src/assets/images/navbar/tariffs.svg";

const EvseCardTariffInfo: FC<{ evse: EvseDetailsResponse }> = ({ evse }) => {
  const { palette } = useTheme();
  return (
    <Grid container spacing={1}>
      <Grid item>
        <SvgIcon component={TariffsIcon} inheritViewBox fontSize="medium" sx={{ color: palette.grey[500] }} />
      </Grid>

      <Grid item>
        <Typography color={palette.grey[500]}>{evse.activeTariffName}</Typography>
      </Grid>
    </Grid>
  );
};

export default EvseCardTariffInfo;
