import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { FC, useState } from "react";
import useDebugStationAction from "src/hooks/debug/useDebugStationAction";
import { notifyErrorRaw } from "../notifications";

const types = ["Inoperative", "Operative"];

const SetConnectorUnavailable: FC<{ stationId: string }> = ({ stationId }) => {
  const [connectorId, setConnectorId] = useState(1);
  const [type, setType] = useState("");
  const { mutate } = useDebugStationAction(stationId);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        if (stationId)
          mutate({
            method: "POST",
            url: "custom",
            body: {
              request_type: "ChangeAvailability",
              request_payload: { connectorId, type },
            },
          });
        else notifyErrorRaw("Station ID is required");
      }}
    >
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <TextField
            label="connectorId"
            size="small"
            required
            value={connectorId}
            onChange={(event) => setConnectorId(Number(event.target.value))}
          />
        </Grid>

        <Grid item xs={4}>
          <FormControl size="small" fullWidth required>
            <InputLabel>type</InputLabel>
            <Select label="type" value={type} onChange={(event) => setType(event.target.value)}>
              {types.map((connectorType) => (
                <MenuItem key={connectorType} value={connectorType}>
                  {connectorType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <Button variant="contained" type="submit">
            SET CONNECTOR UNAVAILABLE
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default SetConnectorUnavailable;
