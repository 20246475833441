import { Box, Card, CardContent, List, ListItem, Typography, useTheme } from "@mui/material";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import getStatusEvents from "src/api/statusEvents";
import { StatusEventsBroadcasterPayload, StatusEventsResponse } from "src/api/statusEvents/interfaces";
import { BroadcasterPayload, SocketEventName, socketWrapper } from "src/providers/SocketProvider";
import { notifyAxiosError } from "../notifications";
import { StatusEventStatus, StatusEventsViewProps } from "./interfaces";

const StatusEventsCard: FC<StatusEventsViewProps> = ({ filter }) => {
  const theme = useTheme();
  const intl = useIntl();
  const [data, setData] = useState<StatusEventsResponse["items"]>([]);
  const statusEventStatuses = [
    StatusEventStatus.Offline,
    StatusEventStatus.Faulted,
    StatusEventStatus.AuthenticationFailed,
    StatusEventStatus.RemoteStartFailed,
    StatusEventStatus.RemoteStopFailed,
    StatusEventStatus.StartTransactionFailed,
  ];

  useEffect(() => {
    getStatusEvents({
      ...filter,
      statuses: statusEventStatuses,
    })
      .then((response) => {
        if (response.data.items) {
          setData(response.data.items.map((issue) => issue));
        }
      })
      .catch((err) => notifyAxiosError(err, intl));

    socketWrapper.onEvent(SocketEventName.StatusEvent, (payload) => {
      const statusEventPayload: BroadcasterPayload<StatusEventsBroadcasterPayload> = JSON.parse(payload);

      const { data: update } = statusEventPayload;
      const { createdAt, ...rest } = update;

      const newStatus = update.status as StatusEventStatus;
      if (statusEventStatuses.includes(newStatus)) {
        setData((oldArray) => [
          {
            ...rest,
            timestamp: createdAt,
          },
          ...oldArray,
        ]);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <CardContent>
        <Typography variant="h3">
          <FormattedMessage id="recentIssues" />
        </Typography>
        {data.length ? (
          <List
            style={{
              maxHeight: "250px",
              overflow: "auto",
              wordBreak: "break-all",
            }}
            disablePadding
          >
            {data.map((issue, index, issues) => (
              <ListItem
                key={issue.id}
                disableGutters
                divider={index < issues.length - 1}
                sx={{ paddingTop: "8px", paddingBottom: "8px" }}
              >
                <Box>
                  <Typography variant="body2" color={theme.palette.primary.light}>
                    {dayjs(issue.timestamp).format("DD-MM-YYYY HH:mm:ss")}
                  </Typography>

                  <Typography variant="body1" color={theme.palette.grey[600]}>
                    {issue.status === StatusEventStatus.Offline && (
                      <FormattedMessage id="chargerIdXxxIsOffline" values={{ Xxx: issue.componentName }} />
                    )}
                    {issue.status === StatusEventStatus.Faulted && (
                      <FormattedMessage id="evseXxxIsFaulted" values={{ Xxx: issue.componentName }} />
                    )}
                    {issue.status === StatusEventStatus.AuthenticationFailed && (
                      <FormattedMessage id="chargerIdXxxAuthFailed" values={{ Xxx: issue.componentName }} />
                    )}
                    {issue.status === StatusEventStatus.RemoteStartFailed && (
                      <FormattedMessage id="evseXxxRemoteStartFailed" values={{ Xxx: issue.componentName }} />
                    )}
                    {issue.status === StatusEventStatus.RemoteStopFailed && (
                      <FormattedMessage id="evseXxxRemoteStopFailed" values={{ Xxx: issue.componentName }} />
                    )}
                    {issue.status === StatusEventStatus.StartTransactionFailed && (
                      <FormattedMessage id="evseXxxStartTransactionFailed" values={{ Xxx: issue.componentName }} />
                    )}
                  </Typography>
                </Box>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography variant="body1" color={theme.palette.primary.light} fontStyle="italic" pt="8px">
            <FormattedMessage id="noRecentIssues" />
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default StatusEventsCard;
