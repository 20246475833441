import { Button } from "@mui/material";
import { FC } from "react";
import useDebugStationAction from "src/hooks/debug/useDebugStationAction";
import { notifyErrorRaw } from "../notifications";

const ClearStationCache: FC<{ stationId: string }> = ({ stationId }) => {
  const { mutate } = useDebugStationAction(stationId);
  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        if (stationId) mutate({ url: "clear_cache" });
        else notifyErrorRaw("Station ID is required");
      }}
    >
      <Button variant="contained" type="submit">
        CLEAR CACHE
      </Button>
    </form>
  );
};

export default ClearStationCache;
