import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";

export type Action = "create" | "read" | "update" | "delete" | "control" | "debug";
export type Subject = "Location" | "Station" | "Session" | "Tariff" | "Staff";

export interface TenantPermission {
  subject: Subject;
  actions: Action[];
}

export interface TenantProfile {
  id: string;
  name: string;
  logoURL: string;
  permissions: TenantPermission[];
}

export interface UserProfile {
  id: string;
  email: string;
  name: string;
  tenants: TenantProfile[];
}

const emptyUserProfile: UserProfile = {
  id: "",
  email: "",
  name: "",
  tenants: [],
};

const emptyTenant: TenantProfile = {
  id: "",
  name: "",
  logoURL: "",
  permissions: [],
};

export class UserProfileStore {
  profile: UserProfile = emptyUserProfile;

  tenant: TenantProfile = emptyTenant;

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "UserProfileStore",
      properties: ["profile", "tenant"],
      storage: window.localStorage,
    });
  }

  setActiveUser(user: UserProfile) {
    this.activeUserProfile.id = user.id;
    this.activeUserProfile.email = user.email;
    this.activeUserProfile.name = user.name;
    this.activeUserProfile.tenants = user.tenants.sort((t1, t2) => t1.name.localeCompare(t2.name));
    this.setActiveTenant(user.tenants[0] ?? emptyTenant);
  }

  setActiveTenant(tenant: TenantProfile) {
    this.activeTenant.id = tenant.id;
    this.activeTenant.name = tenant.name;
    this.activeTenant.logoURL = tenant.logoURL;
    this.activeTenant.permissions = tenant.permissions;
  }

  resetActiveUser() {
    this.setActiveUser(emptyUserProfile);
  }

  get activeUserProfile() {
    return this.profile;
  }

  get activeTenant() {
    return this.tenant;
  }

  get activeTenantId() {
    return this.tenant.id;
  }

  get entityFilter() {
    return {
      tenantId: this.tenant.id,
    };
  }
}
