import { useQuery } from "@tanstack/react-query";
import { getStationSummaries } from "src/api/stations";
import { StationSummaryFilter } from "src/api/stations/interface";

const useGetStationSummaries = (filters: StationSummaryFilter) =>
  useQuery({
    queryKey: ["stations", filters],
    queryFn: () => getStationSummaries(filters),
  });

export default useGetStationSummaries;
