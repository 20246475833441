import { autorun } from "mobx";
import { createContext, useContext } from "react";
import { io } from "socket.io-client";
import { decryptAuthData, userProfileStore } from "src/services/auth";

let socket = io({ autoConnect: false });
export const socketWrapper = {
  onEvent: (name: string, listener: (...args: any[]) => void) => socket.on(name, listener),
};
export const SocketContext = createContext(socketWrapper);
export const useSocket = () => useContext(SocketContext);

let isConnected: boolean = false;
let prevTenantId: string = "";

autorun(() => {
  const { id } = userProfileStore.activeTenant;

  if (id) {
    if (!isConnected) {
      const authInfo = decryptAuthData();

      // this is the first time tenantId was set, so we need to connect
      isConnected = true;
      prevTenantId = id;

      socket = io(process.env.REACT_APP_BROADCASTER_URL || "http://localhost:8111", {
        path: `${process.env.REACT_APP_BROADCASTER_PATH || ""}/socket.io`,
        extraHeaders: {
          Authorization: `Bearer ${authInfo?.token}`,
        },
      });

      socket.on("connect", () => {
        socket.emit("join-room", id);
      });

      socket.on("error", () => {
        isConnected = false;
      });
    } else if (prevTenantId !== id) {
      if (prevTenantId) {
        socket.emit("leave-room", prevTenantId);
      }

      prevTenantId = id;
      socket.emit("join-room", id);
    }
  } else {
    socket.disconnect();
    isConnected = false;
    prevTenantId = "";
  }
});

export interface BroadcasterPayload<T> {
  roomId: string;
  data: T;
  timestamp: string;
}

export enum SocketEventName {
  StatusEvent = "STATUS_EVENT",
  EvseState = "EVSE_STATE",
}
