import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Box, Button, Card, CardContent, Link, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import dayjs, { Dayjs } from "dayjs";
import { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { getStationSummaries } from "src/api/stations";
import { notifyAxiosError } from "src/components/notifications";
import ConnectorIcon from "src/components/stations/ConnectorIcon";
import { StationFilter } from "src/components/stations/interfaces";
import { SocketEventName, socketWrapper } from "src/providers/SocketProvider";
import { Can } from "src/services/permissions/context";
import StatusIcon from "./StatusIcon";

type Connector = {
  kind: string;
  status: string;
  statusOcpp: string;
  statusUpdatedAt: Dayjs;
};

type Evse = {
  id: string;
  idx: number;
  status: string;
  statusOcpp: string;
  statusUpdatedAt: Dayjs;
  powerType: string;
  powerMaxWattage: number;
  connectors: Array<Connector>;
  activeTariffId: string;
  activeTariffName: string;
};

type StationTableItem = {
  id: string;
  name: string;
  model: string;
  status: string;
  evses: Array<Evse>;

  locationId: string;
  locationName: string;
};

interface StationsTableProps {
  filter: StationFilter;
  showLocationColumn?: boolean;
}

const StationsTable: FC<StationsTableProps> = ({ filter, showLocationColumn }) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const [data, setData] = useState<Array<StationTableItem>>([]);

  const fetchData = () => {
    getStationSummaries(filter)
      .then((response) => {
        setData(
          response.data
            .sort((s1, s2) => s1.ocppStationId.localeCompare(s2.ocppStationId))
            .map(({ id, ocppStationId, fullModelName, status, evses, locationId, locationName }) => ({
              id,
              name: ocppStationId,
              model: fullModelName,
              status,
              evses: evses.map((evse, index) => ({
                ...evse,
                statusUpdatedAt: dayjs(evse.statusUpdatedAt),
                idx: index,
                connectors: evse.connectors.map((connector) => ({
                  ...connector,
                  statusUpdatedAt: dayjs(connector.statusUpdatedAt),
                })),
              })),
              locationId,
              locationName,
            })),
        );
      })
      .catch((err) => notifyAxiosError(err, intl));
  };

  useEffect(() => {
    socketWrapper.onEvent(SocketEventName.EvseState, fetchData);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <CardContent>
        <Box display="flex" flexDirection="row" flexGrow={1} justifyContent="space-between">
          <Typography variant="h3" align="left" marginBottom={1}>
            <FormattedMessage id="stations" />
          </Typography>
          <Can I="create" a="Station">
            <Button variant="contained" onClick={() => navigate(`/stations/form/new/${filter.locationId ?? ""}`)}>
              <AddOutlinedIcon fontSize="small" />
              <Typography variant="h6" align="center" marginLeft={1}>
                <FormattedMessage id="addStation" />
              </Typography>
            </Button>
          </Can>
        </Box>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {showLocationColumn && (
                  <TableCell>
                    <FormattedMessage id="location" />
                  </TableCell>
                )}
                <TableCell>
                  <FormattedMessage id="stationId" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="model" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="powerRating" />
                </TableCell>
                <TableCell align="center">
                  <FormattedMessage id="status" />
                </TableCell>
                <TableCell align="center">
                  <FormattedMessage id="connectorStatus" />
                </TableCell>
                <Can I="read" a="Tariff">
                  <TableCell>
                    <FormattedMessage id="tariff" />
                  </TableCell>
                </Can>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow key={row.name} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  {showLocationColumn && (
                    <TableCell component="th" scope="row">
                      <Link underline="hover" href={`/locations/${row.locationId}`}>
                        {row.locationName}
                      </Link>
                    </TableCell>
                  )}
                  <TableCell>
                    <Link underline="hover" href={`/stations/${row.id}`}>
                      {row.name}
                    </Link>
                  </TableCell>
                  <TableCell>{row.model}</TableCell>
                  <TableCell align="right">
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {row.evses.map((evse) => (
                        <Box
                          key={evse.idx}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Typography>{`${evse.powerMaxWattage / 1000} kW`}</Typography>
                          <Typography marginLeft={2}>{evse.powerType.split("_")[0]}</Typography>
                        </Box>
                      ))}
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {row.evses.map((evse) => (
                        <StatusIcon
                          key={`{${evse.id}-status-icon}`}
                          status={evse.status}
                          statusOcpp={evse.statusOcpp}
                          statusUpdatedAt={dayjs(evse.statusUpdatedAt)}
                        />
                      ))}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {row.evses.map((evse) => (
                        <Box
                          key={evse.id}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {evse.connectors.map((connector, index) => (
                            <ConnectorIcon
                              // The order of connectors will always be the same.
                              // Hence, using the index is safe.
                              // eslint-disable-next-line react/no-array-index-key
                              key={`${evse.id}-${index}-connector-icon`}
                              kind={connector.kind}
                              status={connector.status}
                              statusOcpp={connector.statusOcpp}
                              statusUpdatedAt={dayjs(connector.statusUpdatedAt)}
                              evseStatus={evse.status}
                            />
                          ))}
                        </Box>
                      ))}
                    </Box>
                  </TableCell>
                  <Can I="read" a="Tariff">
                    <TableCell align="right">
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {row.evses.map((evse) => (
                          <Box
                            key={evse.idx}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <Typography>{evse.activeTariffName}</Typography>
                          </Box>
                        ))}
                      </Box>
                    </TableCell>
                  </Can>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default StationsTable;
