import { Box, Card, CardContent, Typography, useTheme } from "@mui/material";
import { Chart, registerables } from "chart.js";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { FC, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { FormattedMessage, useIntl } from "react-intl";
import { getEnergyConsumptionChartData } from "src/api/sessions";
import TimeRangePicker, { TimeRangeOption, shortTimeRangeOptions } from "src/components/TimeRangePicker";
import { notifyAxiosError } from "src/components/notifications";
import { SessionsFilter, SessionsViewProps } from "src/components/sessions/interfaces";
import SimpleTabContent from "src/components/tabs/SimpleTabContent";
import { dateFormatters } from "../chart-js/formatters";
import { getBaseLineChartDataset, getBaseLineChartOptions } from "../chart-js/line";
import { getFromDateByRange, getTimeBlockSizeByRange } from "./range-utilities";

dayjs.extend(utc);
dayjs.extend(timezone);
Chart.register(...registerables);

const EnergyConsumptionChartRange: FC<{
  range: TimeRangeOption;
  filter: SessionsFilter;
}> = ({ range, filter }) => {
  const intl = useIntl();
  const { palette } = useTheme();

  const dataEmpty = [{ dateTime: new Date(), value: 0 }];
  const [data, setData] = useState<Array<{ dateTime: Date; value: number }>>(dataEmpty);

  useEffect(() => {
    getEnergyConsumptionChartData({
      ...filter,
      from: getFromDateByRange(range),
      timezone: dayjs.tz.guess(),
      timeBlockSize: getTimeBlockSizeByRange(range),
    })
      .then((response) => {
        if (response.data.items.length) {
          setData(
            response.data.items.map(({ dateTime, value }) => ({
              dateTime: dayjs(dateTime).toDate(),
              value: (value as string | number) === "NaN" ? 0 : value,
            })),
          );
        }
      })
      .catch((err) => notifyAxiosError(err, intl));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const baseChartDataSet = getBaseLineChartDataset();

  let maxTicksLimit;
  if (range === "1D") maxTicksLimit = 12;
  if (range === "1M") maxTicksLimit = 17;

  return (
    <Box display="flex" alignItems="center" width="100%" height={300} mt={3}>
      <Line
        data={{
          datasets: [
            {
              ...baseChartDataSet,
              data: data.map((item) => item.value),
              label: intl.formatMessage({ id: "kWh" }),
            },
          ],
          labels: data.map((item) => dateFormatters[range](item.dateTime)),
        }}
        options={getBaseLineChartOptions(palette, maxTicksLimit)}
      />
    </Box>
  );
};

export const EnergyConsumptionChartEmbedded: FC<{
  ranges: Array<TimeRangeOption>;
  rangeIndex: number;
  filter: SessionsFilter;
}> = ({ ranges, rangeIndex, filter }) => (
  <SimpleTabContent
    tabs={ranges.map((range) => ({
      key: range,
      child: <EnergyConsumptionChartRange range={range} filter={filter} />,
    }))}
    selected={rangeIndex}
  />
);

export const EnergyConsumptionChart: FC<SessionsViewProps> = ({ filter }) => {
  const ranges = shortTimeRangeOptions;
  const [rangeIndex, setRangeIndex] = useState(0);

  const handleRangeChange = (newValue: number) => {
    setRangeIndex(newValue);
  };

  return (
    <Card>
      <CardContent>
        <Box display="flex" width="100%">
          <Box display="flex" width="100%">
            <Typography variant="h3" align="left">
              <FormattedMessage id="energyUsage" />
            </Typography>
          </Box>
          <TimeRangePicker options={ranges} selected={rangeIndex} onChange={handleRangeChange} />
        </Box>
        <EnergyConsumptionChartEmbedded ranges={ranges} rangeIndex={rangeIndex} filter={filter} />
      </CardContent>
    </Card>
  );
};
