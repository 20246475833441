import { Typography, useTheme } from "@mui/material";
import { FC } from "react";

interface HeatMapXAxisLabelProps {
  label: string;
}

const HeatMapXAxisLabel: FC<HeatMapXAxisLabelProps> = ({ label }) => {
  const { palette } = useTheme();
  return (
    <Typography
      sx={{
        fontSize: "12px",
        color: palette.grey[500],
        opacity: "0.6",
        position: "relative",
        left: "30px",
        textAlign: "center",
        whiteSpace: "nowrap",
      }}
    >
      {label}
    </Typography>
  );
};

export default HeatMapXAxisLabel;
