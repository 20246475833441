import { CircleOutlined } from "@mui/icons-material";
import { SvgIcon } from "@mui/material";
import { Dayjs } from "dayjs";
import { FC } from "react";
import { ReactComponent as Chademo } from "src/assets/images/connectors/CHADEMO.svg";
import { ReactComponent as Type1 } from "src/assets/images/connectors/IEC_62196_T1.svg";
import { ReactComponent as Type2 } from "src/assets/images/connectors/IEC_62196_T2.svg";
import { ReactComponent as Type2Combo } from "src/assets/images/connectors/IEC_62196_T2_COMBO.svg";
import { statusColors } from "src/theme/colors";
import StatusInfoTooltip from "../StatusInfoTooltip";

interface ConnectorIconProps {
  kind: string;
  evseStatus: string;
  status: string;
  statusOcpp: string;
  statusUpdatedAt: Dayjs;
}

const mapConnector = (kind: string) => {
  if (kind === "CHADEMO") {
    return Chademo;
  }
  if (kind === "IEC_62196_T1") {
    return Type1;
  }
  if (kind === "IEC_62196_T2") {
    return Type2;
  }
  if (kind === "IEC_62196_T2_COMBO") {
    return Type2Combo;
  }
  return undefined;
};

const offlineStates = ["inoperative", "unavailable", "unknown", "missing"];

const ConnectorIcon: FC<ConnectorIconProps> = ({ kind, evseStatus, status, statusOcpp, statusUpdatedAt }) => {
  const icon = mapConnector(kind);
  const lowercaseStatus = offlineStates.includes(evseStatus.toLocaleLowerCase()) ? "unknown" : status.toLowerCase();

  if (icon) {
    return (
      <StatusInfoTooltip status={status} statusOcpp={statusOcpp} statusUpdatedAt={statusUpdatedAt}>
        <SvgIcon
          component={icon}
          inheritViewBox
          sx={{ color: statusColors.get(lowercaseStatus) ?? "#82A1B0" }}
          fontSize="large"
        />
      </StatusInfoTooltip>
    );
  }
  return (
    <StatusInfoTooltip status={status} statusOcpp={statusOcpp} statusUpdatedAt={statusUpdatedAt}>
      <CircleOutlined sx={{ color: statusColors.get(lowercaseStatus) ?? "#82A1B0" }} fontSize="large" />
    </StatusInfoTooltip>
  );
};

export default ConnectorIcon;
