import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { axiosInstance } from "src/api";
import { notifyErrorRaw, notifySuccessRaw } from "src/pages/DeveloperTools/notifications";

interface StationActionRequestBody {
  url: string;
  method?: string;
  body?: any;
  params?: any;
}

const useDebugStationAction = (stationId: string) =>
  useMutation({
    mutationKey: ["stationAction", stationId],
    mutationFn: (requestBody: StationActionRequestBody) =>
      axiosInstance.post<string>(`/station/${stationId}/debug`, {
        ...requestBody,
        method: requestBody.method ?? "POST",
        body: requestBody.body ?? {},
        params: requestBody.params ?? {},
      }),
    onSuccess: () => notifySuccessRaw("The station action was successfully executed"),
    onError: (error: AxiosError<{ code?: string }>) => notifyErrorRaw(error.response?.data.code ?? error.toString()),
  });

export default useDebugStationAction;
