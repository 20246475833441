import { FC, ReactNode } from "react";

interface SimpleTabContentProps {
  tabs: Array<{ key: string; child: ReactNode }>;
  selected: number;
}

const SimpleTabContent: FC<SimpleTabContentProps> = ({ tabs, selected }) => (
  <>
    {tabs.map((item, index) => (
      <div key={item.key} style={{ display: selected !== index ? "none" : undefined }}>
        {item.child}
      </div>
    ))}
  </>
);

export default SimpleTabContent;
