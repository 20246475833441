import { Box } from "@mui/material";
import dayjs from "dayjs";
import { FC } from "react";
import HeatMapBlock from "./HeatMapBlock";
import HeatMapLegend from "./HeatMapLegend";
import HeatMapXAxisLabel from "./HeatMapXAxisLabel";
import HeatMapYAxisLabel from "./HeatMapYAxisLabel";
import { HeatMapData } from "./interfaces";

// Defines the display order of each day of the week. Each value represents the
// index of the equivalent day of week as defined in dayjs.
const dayIndexes = [1, 2, 3, 4, 5, 6, 0];
// Defines the display order of each hour of the day. Each value represents the
// index of the equivalent hour of the day as defined in dayjs.
const hourIndexes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

interface HeatMapProps {
  data: HeatMapData;
}

const HeatMap: FC<HeatMapProps> = ({ data }) => {
  // The keys represent each index in the `dayIndexes` list. While the value is an
  // array constructed in such a way that each index in `hourIndexes` has a value.
  const map = new Map<number, number[]>([
    [0, new Array(24).fill(0)],
    [1, new Array(24).fill(0)],
    [2, new Array(24).fill(0)],
    [3, new Array(24).fill(0)],
    [4, new Array(24).fill(0)],
    [5, new Array(24).fill(0)],
    [6, new Array(24).fill(0)],
  ]);
  data.items.forEach((item) => {
    const timestamp = dayjs(item.dateTime);
    const day = timestamp.day();
    const hour = timestamp.hour();
    if (map.has(day)) map.get(day)![hour] = item.value;
  });
  return (
    <>
      <HeatMapLegend />
      <Box
        key="heat-map-grid"
        mt="20px"
        sx={{
          display: "grid",
          gridGap: "5px",
          width: "100%",
          overflowX: "auto",
        }}
      >
        {dayIndexes.map((dayIndex) => {
          const timestampAtDay = dayjs().day(dayIndex);
          const dayLabel = dayjs(timestampAtDay).format("ddd");
          return (
            <Box
              key={`y-axis-label-${dayIndex}`}
              sx={{
                display: "grid",
                gridGap: "5px",
                gridTemplateColumns: "repeat(25, 1fr)",
              }}
            >
              <HeatMapYAxisLabel label={dayLabel} />
              {hourIndexes.map((hourIndex) => {
                const intensity = map.get(dayIndex)![hourIndex];
                return <HeatMapBlock key={`block-${dayIndex}-${hourIndex}`} intensity={intensity} />;
              })}
            </Box>
          );
        })}

        <Box
          key="x-axis-label-grid"
          sx={{
            display: "grid",
            gridGap: "5px",
            gridTemplateColumns: "repeat(25, 1fr)",
          }}
        >
          {hourIndexes.map((hourIndex) => {
            const key = `x-axis-label-${hourIndex}`;
            if (hourIndex % 6 === 0) {
              const timestampAtHour = dayjs().hour(hourIndex);
              const hourLabel = dayjs(timestampAtHour).format("h a");
              return <HeatMapXAxisLabel key={key} label={hourLabel} />;
            }
            return <Box key={key} />;
          })}
        </Box>
      </Box>
    </>
  );
};

export default HeatMap;
